import { routes } from "~/constants";
export const restaurationCollective = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/HPKRWzWu",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 entreprises",
    title3: "nous font confiance !",
    subtitle: "restaurants d'entreprise, cantines scolaires…",
  },
  qualifications: {
    title: "Ensemble, trouvons les meilleurs profils pour renforcer vos équipes",
    list: [
      {
        qualificationCategory: "Cuisine",
        qualificationImgSrc:
          "/img/recruteurs/bu/restauration-collective/qualification-cuisine.jpeg",
        qualificationList: [
          {
            name: "Chef de cuisine",
            slug: "chef-de-cuisine",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités et compétences d'un Chef de Cuisine en restauration collective ?",
                text: "Au sein de la cuisine en <strong>restauration collective</strong>, le <strong>chef de cuisine</strong> est le leader incontesté ! Responsable de l'élaboration des menus, de la gestion des achats, il dirige une équipe pour produire des repas en grande quantité, tout en préservant la qualité et le goût. Tout comme le second de cuisine, il se doit d’avoir des connaissances approfondies en nutrition afin de garantir une alimentation saine au public qu’il doit servir (EHPAD, cantine scolaire…). Expert en gestion de production et en optimisation des processus, il assure l'efficacité opérationnelle et le respect des contraintes budgétaires. Chez Extracadabra, vous trouverez de nombreux profils de chef de cuisine qualifiés.",
              },
            },
          },
          {
            name: "Second de cuisine",
            slug: "second-de-cuisine",
            informations: {
              definition: {
                title:
                  "Comment le Second de Cuisine impacte-t-il la restauration collective et quels sont ses horaires de travail ?",
                text: "Le <strong>second de cuisine</strong> en <strong>restauration collective</strong> est le bras droit indispensable du chef de cuisine. Il joue un rôle clé dans la gestion quotidienne de la cuisine, supervisant la préparation des repas, coordonnant le personnel et assurant le respect des standards de qualité et d'hygiène. Sa capacité à gérer efficacement les opérations en l'absence du chef et son talent pour maintenir une production culinaire de haut niveau sont cruciaux pour le bon fonctionnement de la cuisine. Polyvalent, il contribue à la création de menus variés et à la satisfaction des convives. S’il travaille en restaurant d’entreprise ou en cantine scolaire, le second de cuisine aura des horaires standards de travail, il évitera les horaires tardifs.",
              },
            },
          },
          {
            name: "Chef de partie",
            slug: "chef-de-partie",
            informations: {
              definition: {
                title:
                  "Quel est le rôle du Chef de Partie en restauration collective et quels sont ses avantages ?",
                text: "Le <strong>chef de partie</strong> en <strong>restauration collective</strong> est un élément clé dans la préparation de repas à grande échelle. Responsable d'une section spécifique de la cuisine, il supervise la production culinaire, garantissant la qualité et le respect des normes alimentaires. Doté de solides compétences en gestion d'équipe, il coordonne les activités des commis, veillant à l'efficacité et à la fluidité du service. En restauration collective, le chef de partie a l’avantage de pouvoir travailler sur des horaires plus compatibles avec une vie de famille. Les meilleurs profils de chef de partie sont dans la communauté Extracadabra !",
              },
            },
          },
          {
            name: "Employé polyvalent",
            slug: "employe-polyvalent",
            informations: {
              definition: {
                title:
                  "Quelles sont les tâches et l'importance de l'Employé Polyvalent en restauration collective ?",
                text: "L’<strong>employé polyvalent de restauration collective</strong> prépare et assemble des produits, des plats simples chauds ou froids. Il connaît bien les règles d’hygiène et doit veiller à la propreté des locaux et du matériel. L'employé polyvalent facilite le service par les multiples petites attentions qu'il porte à tous les détails en cuisine.",
              },
            },
          },
          {
            name: "Demi-chef de partie",
            slug: "demi-chef-de-partie",
            informations: {
              definition: {
                title:
                  "En quoi consiste le rôle du Demi-Chef de Partie en restauration collective ?",
                text: "Le <strong>demi chef de partie</strong> est le bras droit du chef de partie. Dans ce poste, il affine ses compétences culinaires et sa compréhension des différentes sections de la cuisine. Responsable de tâches spécifiques, il aide à préparer des plats en grande quantité, adaptés aux spécificités de la restauration collective. Il gère les stocks et s'assure que les normes de sécurité et d'hygiène sont respectées.",
              },
            },
          },
          {
            name: "Commis de cuisine",
            slug: "commis-de-cuisine",
            informations: {
              definition: {
                title:
                  "Quel est le parcours d'un Commis de Cuisine en restauration collective et ses responsabilités ?",
                text: "Le <strong>commis de cuisine en restauration collective</strong> assiste les chefs dans la préparation des repas à grande échelle, apprenant activement les techniques culinaires et contribuant à la production efficace des plats. Sa tâche inclut la préparation des ingrédients, le maintien de la propreté de la cuisine et la réalisation de tâches culinaires de base sous la supervision de chefs expérimentés. Son engagement et sa capacité à s'adapter rapidement sont cruciaux pour offrir une expérience de restauration de qualité aux convives, tout comme le reste de la brigade, il est particulièrement attentif à l’hygiène et possède une certification HACCP.",
              },
            },
          },
          {
            name: "Pâtissier",
            slug: "patissier",
            informations: {
              definition: {
                title:
                  "Quelles sont les compétences et responsabilités d'un Pâtissier en restauration collective ?",
                text: "Le <strong>pâtissier en restauration collective</strong> est un artisan spécialisé dans la création de desserts et de pâtisseries. Capable de produire de grosse quantité de gâteaux, tartes et autres desserts de qualité, il joue un rôle essentiel en apportant une touche finale gourmande et raffinée aux repas, tout en respectant les normes d'hygiène et de production en volume.",
              },
            },
          },
          {
            name: "Pizzaïolo",
            slug: "pizzaiolo",
            informations: {
              definition: {
                title:
                  "Quel est le rôle du Pizzaïolo dans un restaurant d'entreprise et ses contributions ?",
                text: "Le <strong>pizzaïolo</strong> peut officier dans certains <strong>restaurants d’entreprise</strong>. Il maîtrise l'art de préparer la pâte, de choisir des ingrédients de qualité et de cuire les pizzas à la perfection, offrant une variété de choix pour satisfaire tous les goûts. Il participe aussi à l’animation de la cantine et rend ce temps de pause d’autant plus agréable. Sa capacité à produire des pizzas de haute qualité en volume est essentielle pour répondre efficacement aux demandes des convives.",
              },
            },
          },
          {
            name: "Plongeur",
            slug: "plongeur",
            informations: {
              definition: {
                title:
                  "Pourquoi le Plongeur est-il essentiel en restauration collective et quels profils sont recherchés ?",
                text: "Le <strong>plongeur en restauration collective</strong> joue un rôle crucial dans le maintien de la propreté et de l'hygiène de la cuisine. Chargé du nettoyage des ustensiles, de la vaisselle et des équipements, il assure un environnement de travail sain et organisé. Sa contribution est essentielle pour le bon fonctionnement de la cuisine, permettant aux cuisiniers et au personnel de se concentrer sur la préparation des repas. Sa rigueur et son efficacité sont indispensables dans le contexte de la restauration à grande échelle, où la propreté est primordiale. De nombreux plongeurs qualifiés sont disponibles dans notre vivier de candidats.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Salle / Bar",
        qualificationImgSrc:
          "/img/recruteurs/bu/restauration-collective/qualification-salle.jpeg",
        qualificationList: [
          {
            name: "Chef de rang",
            slug: "chef-de-rang",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités d'un Chef de Rang en restauration collective ?",
                text: "Le <strong>chef de rang</strong> en <strong>restauration collective</strong> s’assure d’un service fluide et professionnel. Responsable de la salle à manger en EHPAD, du lieu de restauration en entreprise, il coordonne le service, veille à la satisfaction des convives et assure le bon déroulement des repas. Avec empathie, il gère l'accueil des convives, répond à leurs besoins et contribue à une expérience de restauration agréable et organisée.",
              },
            },
          },
          {
            name: "Serveur",
            slug: "serveur",
            informations: {
              definition: {
                title:
                  "Comment le Serveur contribue-t-il à l'expérience de restauration collective ?",
                text: "Le <strong>serveur en restauration collective</strong> est essentiel pour assurer un service de repas efficace et convivial. Il est chargé de servir les plats, de débarrasser les tables et d'assister les convives. Habile avec un plateau, sa rapidité et sa capacité à répondre aux demandes garantissent une expérience de restauration fluide et agréable ! Sa présence attentive et courtoise contribue à créer une atmosphère chaleureuse et accueillante, essentielle dans un environnement de restauration collective.",
              },
            },
          },
          {
            name: "Barista",
            slug: "barista",
            informations: {
              definition: {
                title:
                  "Quel est le rôle d'un Barista en restauration collective, et comment influence-t-il la pause café ?",
                text: "En collectivité, le <strong>barista</strong> est un spécialiste du café, il officie souvent en restaurant ou cafétéria d’entreprise apportant sa créativité à chaque tasse. Il maîtrise la préparation du café, des expressos classiques ou d’autres boissons chaudes. Le barista participe à une pause café réussie au sein de l’entreprise, établissement où il officie.",
              },
            },
          },
          {
            name: "Barman",
            slug: "barman",
            informations: {
              definition: {
                title:
                  "Quelles compétences sont requises pour un Barman en restauration collective ?",
                text: "Le <strong>barman</strong> est spécialisé dans la préparation et le service de boissons variées. Il joue un rôle essentiel en offrant une gamme étendue de boissons, des jus frais aux cocktails classiques (sans alcools bien sûr !), il sait s’adapter parfaitement aux différents types de convives que compte la collectivité. Le barman de la restauration collective doit parfaitement maîtriser les règles du plan sanitaire (un diplôme HACCP est un vrai plus) et participer à faire des moments de pause et de repas des instants conviviaux pour les bénéficiaires.",
              },
            },
          },
        ],
      },
    ],
  },
  verbatims: {
    title: "Nos clients en restauration collective en parlent mieux que nous…",
  },
  faqs: {
    title: "Recruteurs : Vos questions, nos réponses",
    list: [
      {
        question: "Combien coûte un Extra ?",
        answer:
          "Chez Extracadabra, le coût d'un extra ou renfort en restauration collective est fixe et sans surprise. Barman, cuisinier, plongeur, chef de parti… En fonction de la qualification que vous recherchez, le tarif de la prestation sera ajusté. Vous trouverez par exemple, votre futur barman en restauration collective pour 22 euros de l’heure ou votre cuisinier en restauration collective pour 20,90 euros de l’heure.",
      },
      {
        question: " Qu’est-ce qui est compris dans vos tarifs ?",
        answer:
          "Nos tarifs comprennent la mise en relation entre votre établissement et votre futur renfort",
      },
      {
        question: "Comment travailler avec Extracadabra ?",
        answer:
          "Extracadabra, c'est simple comme un claquement de doigt ! En plus de notre plateforme en ligne, vous pouvez prendre contact directement par téléphone pour un accompagnement personnalisé. Vous obtiendrez une réponse à toutes les questions que vous vous posez quels que soient vos besoins. Autrement, il ne vous reste plus qu'à créer votre compte sans engagement. Après quelques questions et 5 minutes de votre temps, votre compte sera créé et vous pourrez poster votre première demande gratuitement pour trouver le talent qu'il vous faut !",
      },
      {
        question:
          "Travaillez-vous avec d'autres secteurs que la restauration collective ?",
        answer:
          "Nous répondons également aux besoins de la restauration commerciale, des traiteurs, de l'évènementiel, de l'hôtellerie et de la vente Food et Hors-food.",
      },
      {
        question: "Comment cela se passe-t-il si j'ai plusieurs sites ?",
        answer:
          "Si vous gérez plusieurs sites de restauration, pas de souci, nous avons un accès multicompte qui vous permettra de gérer plusieurs établissements à la fois.",
      },
    ],
  },
  leadGen: {
    book: {
      src: "/img/recruteurs/book_lead_gen.png",
      alt: "illustration du e-book Extracadabra",
    },
  },
  footer: {
    legals: {
      cancelation: `*Si vous n’annulez pas une prestation moins de 72 heures avant. <a href='${routes.cgu}'>en savoir plus</a>`,
    },
  },
};
