import type { FetchError } from "ofetch";
import type { AsyncDataOptions } from "#app";
import type { APIParams, APIResponse } from "~/models/Api";
import type { Partner, Partners } from "~/models/Partner";
import HttpFactory from "~/repository/factory";

class PartnersModule extends HttpFactory {
  private resourcePath = "/api/partners";

  async list<T = APIResponse<Partners>, R = T>(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      "partners",
      () => {
        const params = {
          populate: {
            logo: true,
            partner_groups: true,
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async listByGroupSlug<T = APIResponse<Partner<string>[]>, R = T>(
    { slug }: { slug: Ref<string> | string },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      `partners:listByGroupSlug:${slug}`,
      () => {
        const url = `${this.resourcePath}/list-by-group-slug/${slug}`;

        return this.call({
          method: "GET",
          url,
        });
      },
      asyncDataOptions,
    );
  }
}

export default PartnersModule;
