export const pushQualificationBu = {
  etab: [
    {
      imgSrcDesktop: "/img/products-bu/cafe-restaurant-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/cafe-restaurant-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/hotel-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/hotel-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/resto-co-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/resto-co-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/te-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/te-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/commerce-bouche-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/commerce-bouche-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/vente-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/vente-bg-mobile.webp",
    },
  ],
  candidates: [
    {
      imgSrcDesktop: "/img/products-bu/cafe-restaurant-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/cafe-restaurant-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/resto-co-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/resto-co-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/evenementiel-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/evenementiel-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/hotellerie-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/hotellerie-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/logistique-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/logistique-bg-mobile.webp",
    },
    {
      imgSrcDesktop: "/img/products-bu/vente-bg-desktop.webp",
      imgSrcMobile: "/img/products-bu/vente-bg-mobile.webp",
    },
  ],
};
