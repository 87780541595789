import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoIconArrowLeft, LazySvgoIconCheck, LazySvgoIconCheckbox, LazySvgoIconCross, LazySvgoIconDownload, LazySvgoIconGift, LazySvgoIconSpinner } from '#components'
const lazyGlobalComponents = [
  ["SvgoIconArrowLeft", LazySvgoIconArrowLeft],
["SvgoIconCheck", LazySvgoIconCheck],
["SvgoIconCheckbox", LazySvgoIconCheckbox],
["SvgoIconCross", LazySvgoIconCross],
["SvgoIconDownload", LazySvgoIconDownload],
["SvgoIconGift", LazySvgoIconGift],
["SvgoIconSpinner", LazySvgoIconSpinner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
