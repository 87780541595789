import type { AsyncDataOptions } from "#app";
import HttpFactory from "~/repository/factory";
import type { Popup } from "~/models/Popup";

class PopupsModule extends HttpFactory {
  private resourcePath = "/api/popups";

  async findOneBySlug(
    {
      slug,
    }: {
      slug?: Ref<string> | string;
    },
    asyncDataOptions?: AsyncDataOptions<Popup>,
  ) {
    return await useAsyncData<Popup>(
      `popupsBySlug::${slug}`,
      () => {
        const url = `${this.resourcePath}/${slug}`;

        return this.call({
          method: "GET",
          url,
        });
      },
      asyncDataOptions,
    );
  }
}

export default PopupsModule;
