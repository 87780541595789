import type { AsyncDataOptions } from "#app";
import HttpFactory from "~/repository/factory";
import type { APIError } from "~/models/Api";
import type { StrapiFile } from "~/models/strapi/Strapi";

type PopupDataRegisterBody = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  popupSlug: string;
};

type PopupDataRegister = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  file: StrapiFile;
};

class PopupDataModule extends HttpFactory {
  private resourcePath = "/api/popup-datas";

  async register(
    { firstName, lastName, company, email, popupSlug }: PopupDataRegisterBody,
    asyncDataOptions?: AsyncDataOptions<PopupDataRegister>,
  ) {
    return await useAsyncData<PopupDataRegister, { data: APIError }>(() => {
      const url = `${this.resourcePath}/register`;

      return this.call({
        method: "POST",
        url,
        data: {
          firstName,
          lastName,
          company,
          email,
          popupSlug,
        },
      });
    }, asyncDataOptions);
  }
}

export default PopupDataModule;
