import { routes } from "~/constants";
export const hotellerie = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/FVgb08tT",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 entreprises",
    title3: "nous font confiance !",
    subtitle: "Hôtels, Palaces, Musées, parc de loisirs…",
  },
  qualifications: {
    title: "Ensemble, trouvons les meilleurs profils pour renforcer vos équipes",
    list: [
      {
        qualificationCategory: "Salle",
        qualificationImgSrc: "/img/recruteurs/bu/hotellerie/qualification-salle.jpeg",
        qualificationList: [
          {
            name: "Responsable de salle",
            slug: "responsable-de-salle",
            informations: {
              definition: {
                title:
                  "Quel est le rôle clé d'un Responsable de Salle dans l'hôtellerie ?",
                text: "Le <strong>responsable de salle</strong> dirige et coordonne les équipes de salle pour assurer un service fluide et de haute qualité. Expert en gestion d'équipe et en organisation, il veille à ce que chaque client de l’hôtel ou du restaurant dans lequel il officie, bénéficie d'une expérience de repas exceptionnelle, de l'accueil à la fin du service. Sa capacité à résoudre efficacement les problèmes et à maintenir des standards reflète l'engagement du <strong>groupe hôtelier</strong> ou de restauration en matière de service et de satisfaction client. Retrouvez des centaines de profils qualifiés de responsable de salle spécialisé en hôtellerie ou en restauration sont disponibles chez Extracadabra !",
              },
            },
          },
          {
            name: "Maître d’hôtel",
            slug: "maitre-d-hotel",
            informations: {
              definition: {
                title:
                  "Comment le Maître d'Hôtel élève-t-il l'expérience client en hôtellerie ?",
                text: "En <strong>hôtel</strong> ou restaurant, le <strong>maître d'hôtel</strong> incarne l'élégance et la haute qualité de service dans l'établissement. Responsable de superviser l'ensemble du service de restauration, il assure une expérience client exceptionnelle, de l'accueil des clients à la gestion des demandes spéciales. Avec un œil expert pour les détails et une gestion impeccable de l'équipe de salle, le maître d'hôtel contribue à créer une atmosphère raffinée et accueillante. Son rôle est essentiel pour maintenir et renforcer la réputation de l'hôtel en matière d'excellence en restauration et en service client.",
              },
            },
          },
          {
            name: "Chef de rang",
            slug: "chef-de-rang",
            informations: {
              definition: {
                title:
                  "Quelle est l'importance d'un Chef de Rang dans le service hôtelier ?",
                text: "Le <strong>chef de rang</strong> dans un groupe <strong>hôtelier</strong> ou restaurant est un professionnel expert dans le domaine de la restauration. Responsable de la gestion d'une section spécifique du restaurant de l'hôtel, il supervise le service des tables, coordonne les serveurs et s'assure de la satisfaction totale des clients. Avec une excellente connaissance du menu et une capacité à offrir des recommandations personnalisées, le chef de rang assure une expérience de repas fluide et mémorable. Il incarne les standards élevés de service du <strong>groupe hôtelier</strong>, contribuant à une atmosphère accueillante et à un service client irréprochable.",
              },
            },
          },
          {
            name: "Serveur",
            slug: "serveur",
            informations: {
              definition: {
                title:
                  "Quelles compétences distinguent un Serveur dans le secteur hôtelier ?",
                text: "Le <strong>serveur</strong> prend les commandes et sert les plats et les boissons aux clients, il est indispensable au bon déroulement du service en salle. Ses missions principales sont de se charger d’accueillir la clientèle, d'offrir un excellent service pour assurer la satisfaction des clients, et de prendre les commandes. Le serveur doit également être dans la capacité de pouvoir recommander des plats, répondre aux questions et donner des informations supplémentaires aux clients. Il doit aussi se charger de l’encaissement.",
              },
            },
          },
          {
            name: "Runner",
            slug: "runner",
            informations: {
              definition: {
                title: "En quoi consiste le travail d'un Runner en hôtellerie ?",
                text: "Le <strong>runner</strong> travaille en salle sous les ordres du chef de rang, il apporte les plats aux clients et débarrasse les tables. Très actif, il fait des allers-retours entre la salle et la cuisine. Les missions principales du runner sont donc de nettoyer le restaurant, dresser les tables, et servir les clients. Actuellement, <strong>plus de 2 000 runners sont disponibles</strong> dans notre vivier de candidats à <strong>Paris et à Lyon.</strong>",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Cuisine",
        qualificationImgSrc: "/img/recruteurs/bu/hotellerie/qualification-cuisine.jpeg",
        qualificationList: [
          {
            name: "Chef",
            slug: "chef",
            informations: {
              definition: {
                title:
                  "Quelle est la contribution d'un Chef dans la gastronomie hôtelière ?",
                text: "Le <strong>chef de cuisine dans de grands établissements</strong> est un véritable maître culinaire, un innovateur qui apporte créativité et expertise à chaque plat. Responsable de la conception des menus et de la direction d'une brigade de cuisine, il garantit des expériences gastronomiques exceptionnelles pour les clients. Avec un engagement profond pour la qualité, l'originalité, il doit assurer le sourcing des produits et gérer la relation avec les fournisseurs. Son rôle est essentiel pour garantir le positionnement de l’hôtel dans lequel il officie.",
              },
            },
          },
          {
            name: "Chef de partie",
            slug: "chef-de-partie",
            informations: {
              definition: {
                title:
                  "Comment un Chef de Partie influence-t-il la qualité culinaire en hôtellerie ?",
                text: "Le <strong>chef de partie est un artisan culinaire dédié</strong>, spécialisé dans un domaine spécifique de la cuisine. Que ce soit en pâtisserie, grillades, sauces ou entrées, il apporte son expertise et sa créativité pour perfectionner chaque plat. Responsable de sa section, il veille à la qualité, à la préparation et à la présentation des mets, contribuant significativement à l'excellence des repas servis. Son rôle est crucial dans le maintien des standards élevés de la cuisine de l’établissement où il travaille. Des milliers de chefs de partie sont disponibles à <strong>Paris</strong> et à <strong>Lyon</strong> au sein de la communauté Extracadabra",
              },
            },
          },
          {
            name: "Commis de cuisine",
            slug: "commis-de-cuisine",
            informations: {
              definition: {
                title:
                  "Quel est le rôle d'un Commis de Cuisine dans un contexte hôtelier ?",
                text: "Le <strong>commis de cuisine</strong> est la pierre angulaire de la brigade de cuisine. En tant qu'apprenti passionné, il assiste les chefs de partie et le chef de cuisine dans la préparation des plats, l'apprentissage des techniques culinaires et le maintien de l'ordre et de la propreté en cuisine. Son rôle est essentiel pour garantir un service fluide et efficace. ",
              },
            },
          },
          {
            name: "Plongeur",
            slug: "plongeur",
            informations: {
              definition: {
                title:
                  "Quelle est l'importance d'un Plongeur dans la cuisine d'un hôtel ?",
                text: "Celui qui œuvre en coulisse, c’est le <strong>plongeur</strong> bien sûr ! Sans lui, la cuisine ne tournerait pas à son plein potentiel. Armé de son éponge, il gère les montagnes de vaisselle et les casseroles récalcitrantes. Avec rapidité et efficacité dignes, il assure que chaque assiette, verre et ustensile soient parfaitement propres. Il est le garant de l’hygiène et de la propreté de la cuisine.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Bar",
        qualificationImgSrc: "/img/recruteurs/bu/hotellerie/qualification-bar.jpeg",
        qualificationList: [
          {
            name: "Mixologue",
            slug: "mixologue",
            informations: {
              definition: {
                title: "Qu'est-ce qui caractérise un Mixologue dans un cadre hôtelier ?",
                text: "Avec une expertise en <strong>mixologie moderne</strong> et une connaissance approfondie des spiritueux, le <strong>mixologue crée des cocktails</strong> qui sont non seulement délicieux mais aussi visuellement spectaculaires. Chaque création est une alchimie de saveurs faite pour surprendre les clients. Le mixologue sera la star de votre bar d’hôtel ou restaurant, captivant les invités par son habileté et son charisme, et contribuant à une expérience inégalée. De nombreux mixologues qualifiés sont disponibles sur Paris et sa région ainsi qu’à Lyon au sein de notre communauté.",
              },
            },
          },
          {
            name: "Barman",
            slug: "barman",
            informations: {
              definition: {
                title:
                  "Comment le Barman contribue-t-il à l'expérience client en hôtellerie ?",
                text: "Le <strong>barman dans l’hôtellerie et la restauration</strong> est un virtuose du cocktail ! Avec un savoir-faire en mixologie et une connaissance approfondie des spiritueux, il concocte des cocktails classiques et innovants, ajoutant une touche de spectacle à chaque commande. Il est plus qu'un préparateur de boissons : il est un créateur d'ambiance, engageant les clients, et faisant du bar un point de rencontre incontournable de l'hôtel par exemple. ",
              },
            },
          },
          {
            name: "Barista",
            slug: "barista",
            informations: {
              definition: {
                title: "Quel est le rôle d'un Barista dans l'ambiance d'un hôtel ?",
                text: "Maestro de l'espresso et des créations à base de café, le barista transforme chaque commande en une œuvre d’art gustative. De l'espresso classique aux latte art sophistiqués, chaque tasse est une invitation au voyage. Son savoir-faire et son accueil chaleureux créent une ambiance conviviale et raffinée, faisant de la pause café un moment privilégié pour les clients.",
              },
            },
          },
          {
            name: "Sommelier",
            slug: "sommelier",
            informations: {
              definition: {
                title: "En quoi un Sommelier est-il essentiel dans le service hôtelier ?",
                text: "Que ce soit en hôtellerie ou en restauration, le sommelier est un véritable virtuose du vin, un guide dans l'univers des cépages. Avec une connaissance encyclopédique des vins et un palais affiné, il crée des accords mets et vins qui subliment chaque repas. Plus qu'un expert, il est un conteur, évoquant l'histoire et la passion derrière chaque bouteille. Sa présence élégante et son approche personnalisée participent à l’expérience des clients, faisant de chaque dégustation une découverte sensorielle.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Hôtellerie",
        qualificationImgSrc:
          "/img/recruteurs/bu/hotellerie/qualification-hotellerie.jpeg",
        qualificationList: [
          {
            name: "Réceptionniste",
            slug: "receptionniste",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités d'un Réceptionniste dans un hôtel ?",
                text: "Le <strong>réceptionniste en hôtellerie</strong> est souvent la première personne que le client rencontre. Il doit accueillir les clients à leur arrivée à l'hôtel, gérer les réservations et les enregistrements, répondre aux demandes et aux questions des clients, et fournir un service exceptionnel afin de rendre l’arrivée et le séjour agréable. Le réceptionniste aide aussi à résoudre tout problème pouvant survenir pendant leur séjour.",
              },
            },
          },
          {
            name: "Night Auditor",
            slug: "night-auditor",
            informations: {
              definition: {
                title: "Quel est le rôle d'un Night Auditor dans l'hôtellerie ?",
                text: "Le <strong>night auditor</strong> est en quelque sorte le référent de nuit ! Le veilleur de nuit (en français) gère la réception de l'hôtel pendant la nuit, tout en effectuant des tâches administratives et comptables. Ce rôle inclut l'enregistrement des arrivées et des départs tardifs, la réponse aux demandes des clients en dehors des heures normales. Le Night Auditor joue un rôle crucial en assurant le bon fonctionnement de l'hôtel 24 heures sur 24 et contribue à maintenir un niveau élevé de service client, même en dehors des heures d'ouverture habituelles.",
              },
            },
          },
          {
            name: "Femme / Valet de chambre",
            slug: "femme-de-chambre",
            informations: {
              definition: {
                title:
                  "Quelles sont les contributions d'une Femme ou d'un Valet de Chambre à l'hôtellerie ?",
                text: "Avec une touche de magie et beaucoup de professionnalisme, les <strong>femmes de chambre</strong> et <strong>valets de chambre</strong> sont les experts de la propreté et transforment chaque chambre en un petit havre de paix et de confort. Entre le pliage impeccable des draps, le brillant des salles de bains et la parfaite harmonie des serviettes et des articles de toilette, ils assurent une expérience accueillante et impeccable pour chaque client. Véritables fées du logis, les femmes et valets de chambre sont les héros discrets qui donnent vie et éclat à l'hôtel, rendant chaque séjour inoubliable.",
              },
            },
          },
          {
            name: "Bagagiste",
            slug: "bagagiste",
            informations: {
              definition: {
                title:
                  "En quoi un Bagagiste est-il crucial dans l'expérience hôtelière ?",
                text: "Être <strong>bagagiste dans l'hôtellerie</strong>, c’est avant tout prendre soin des biens d’autrui. Le bagagiste doit être prêt à aider en prenant en charge les bagages comme s'ils étaient des trésors. Ils jonglent habilement entre les valises, les sacs, et les demandes des clients, guidant chacun vers sa chambre avec une connaissance experte de l'hôtel. Le bagagiste est souvent le premier contact du client avec l'hôtel, il doit être souriant et avoir le sens du service. Toujours avec un mot aimable, il ajoute cette touche personnelle qui transforme une simple arrivée en une entrée remarquable dans l'univers de l'hôtellerie.",
              },
            },
          },
        ],
      },
    ],
  },
  faqs: {
    title: "Recruteurs : Vos questions, nos réponses",
    list: [
      {
        question: "Combien coûte un Extra ?",
        answer:
          "Chez Extracadabra, le coût d'un extra ou renfort en hôtellerie restauration varie selon la qualification.<br /><br />Sachez que notre grille tarifaire est fixe et que vous n’aurez plus aucun coût supplémentaire à ajouter. Le taux horaire est à comparer à un salaire chargé.<br /><br /><u>Par exemple</u>, votre futur <strong>serveur pour 21,50 euros de l’heure</strong> ou votre <strong>chef de partie pour 23,50 euros de l’heure</strong>.<br /><br />Et la bonne nouvelle, c’est qu'extracadabra vous factura uniquement pour les heures réellement effectuées.<br /><br /> <u>Exemple :</u> vous aviez pris 1 serveur pour votre terrasse de 18h à minuit. Il commence à pleuvoir à 22h30, vous pouvez le renvoyer chez lui à 23h et n’être que facturé de 18h à 23h.",
      },
      {
        question: " Qu’est-ce qui est compris dans vos tarifs ?",
        answer:
          "TOUT !<br /><br/>La rémunération de votre extra et la commission d’Extracadabra.<br />Vous n’aurez plus rien à ajouter.",
      },
      {
        question: "Comment travailler avec Extracadabra ?",
        answer:
          "Extracadabra, c'est simple comme un claquement de doigt ! En plus de notre plateforme en ligne, vous pouvez prendre contact directement par téléphone pour un accompagnement personnalisé. Vous obtiendrez une réponse à toutes les questions que vous vous posez quels que soient vos besoins. Autrement, il ne vous reste plus qu'à créer votre compte sans engagement. Après quelques questions et 5 minutes de votre temps, votre compte sera créé et vous pourrez poster votre première demande gratuitement pour trouver le talent qu'il vous faut !",
      },
      {
        question: "Quelles sont les qualifications proposées par Extracadabra ?",
        answer:
          "Extracadabra se distingue par son large éventail de professionnels qualifiés dans divers secteurs de l'hôtellerie et de la restauration. Que vous recherchiez un maître d'hôtel expérimenté, des runners dynamiques, des chefs de cuisine compétents ou des réceptionnistes dévoués, nous avons le candidat parfait pour compléter votre équipe en salle, en cuisine ou à l'accueil !",
      },
      {
        question:
          "Comment cela se passe-t-il si j'ai plusieurs établissements ou plusieurs services qui ont besoin de leur propre compte Extracadabra ?",
        answer:
          'Vous gérez plusieurs hôtels et/ou restaurants ou départements d’un même hôtel, le multicompte est fait pour vous.<br /><br />Le multicompte chez Extracadabra permet d\'avoir de la visibilité sur tous vos établissements ou services avec un accès unique; Tout en laissant chaque directeur responsable de son seul et unique compte (si vous le souhaitez un jour).<br /><br />Le multicompte est complètement gratuit.<br /><br />Comment l’obtenir ? Faites une demande multicompte directement sur <a href="https://extracadabra-paris.typeform.com/to/BJa7faSu" target="_blank">ce lien</a>',
      },
    ],
  },
  otherCities: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: "#",
        label: "EN SAVOIR PLUS",
      },
      title: "Trouvez les meilleurs talents <strong>à Lyon</strong> avec Extracadabra",
      description:
        "Confiez-nous vos besoins de recrutement à Lyon et bénéficiez de notre expertise pour dénicher les candidats les plus qualifiés",
    },
  ],
  leadGen: {
    book: {
      src: "/img/recruteurs/book_lead_gen.png",
      alt: "illustration du e-book Extracadabra",
    },
  },
  footer: {
    legals: {
      cancelation: `*Si vous n’annulez pas une prestation moins de 72 heures avant. <a href='${routes.cgu}'>en savoir plus</a>`,
    },
  },
};
