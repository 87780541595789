export const popups = {
  leadGenPopup: {
    slug: "popup-lead-gen-magazine",
  },
  bu: {
    restaurationCollective: {
      slug: "lead-gen-restauration-collective",
    },
    traiteurEvenementiel: {
      slug: "lead-gen-traiteur-evenementiel",
    },
  },
  forms: {
    landingMetro: {
      cardNumber: {
        src: "https://static-cadabra.s3.amazonaws.com/www/landing/formulaires/popup/metro_card_client_number_mobile.webp",
        srcDesk:
          "https://static-cadabra.s3.amazonaws.com/www/landing/formulaires/popup/metro_card_client_number_desktop.webp",
      },
    },
  },
};
