export const accordion = {
  etab: {
    mobile: [
      {
        answers: [
          {
            src: "/img/recruteurs/img-etape1.webp",
            alt: "screenshot 1",
          },
        ],
      },
      {
        answers: [
          {
            src: "/img/recruteurs/img-etape2-1.webp",
            alt: "screenshot 2-1",
          },
          {
            src: "/img/recruteurs/img-etape2-2.webp",
            alt: "screenshot 2-2",
          },
          {
            src: "/img/recruteurs/img-etape2-3.webp",
            alt: "screenshot 2-3",
          },
          {
            src: "/img/recruteurs/img-etape2-4.webp",
            alt: "screenshot 2-4",
          },
        ],
      },
      {
        answers: [
          {
            src: "/img/recruteurs/img-etape3-1.webp",
            alt: "screenshot 3-1",
          },
          {
            src: "/img/recruteurs/img-etape3-2.webp",
            alt: "screenshot 3-3",
          },
        ],
      },
    ],
    desktop: [
      {
        src: "/img/recruteurs/etape-1.png",
        alt: "Screenshot 1 - web app Extracadabra",
      },
      {
        src: "/img/recruteurs/etape-2.png",
        alt: "Screenshot 2 - web app Extracadabra",
      },
      {
        src: "/img/recruteurs/etape-3.png",
        alt: "Screenshot 3 - web app Extracadabra",
      },
    ],
  },
  etabLyon: {
    mobile: [
      {
        answers: [
          {
            src: "/img/recruteurs/img-etape1.webp",
            alt: "screenshot 1",
          },
        ],
      },
      {
        answers: [
          {
            src: "/img/recruteurs/img-etape2-1.webp",
            alt: "screenshot 2-1",
          },
          {
            src: "/img/recruteurs/img-etape2-2.webp",
            alt: "screenshot 2-2",
          },
          {
            src: "/img/recruteurs/img-etape2-3.webp",
            alt: "screenshot 2-3",
          },
          {
            src: "/img/recruteurs/img-etape2-4.webp",
            alt: "screenshot 2-4",
          },
        ],
      },
      {
        answers: [
          {
            src: "/img/recruteurs/img-etape3-1.webp",
            alt: "screenshot 3-1",
          },
          {
            src: "/img/recruteurs/img-etape3-2.webp",
            alt: "screenshot 3-3",
          },
        ],
      },
    ],
    desktop: [
      {
        src: "/img/landing/etab-lyon/etape-1.png",
        alt: "Screenshot 1 - web app Extracadabra",
      },
      {
        src: "/img/recruteurs/etape-2.png",
        alt: "Screenshot 2 - web app Extracadabra",
      },
      {
        src: "/img/recruteurs/etape-3.png",
        alt: "Screenshot 3 - web app Extracadabra",
      },
    ],
  },
  candidates: {
    mobile: [
      {
        answers: [
          {
            src: "/img/candidats/img-etape1.webp",
            alt: "screenshot 1",
          },
        ],
      },
      {
        answers: [
          {
            src: "/img/candidats/img-etape2-1.webp",
            alt: "screenshot 2-1",
          },
          {
            src: "/img/candidats/img-etape2-2.webp",
            alt: "screenshot 2-2",
          },
          {
            src: "/img/candidats/img-etape2-3.webp",
            alt: "screenshot 2-3",
          },
          {
            src: "/img/candidats/img-etape2-4.webp",
            alt: "screenshot 2-4",
          },
        ],
      },
      {
        answers: [
          {
            src: "/img/candidats/img-etape3-1.webp",
            alt: "screenshot 3-1",
          },
          {
            src: "/img/candidats/img-etape3-2.webp",
            alt: "screenshot 3-3",
          },
        ],
      },
    ],
    desktop: [
      {
        src: "/img/candidats/etape-1.png",
        alt: "Screenshot 1 - web app Extracadabra",
      },
      {
        src: "/img/candidats/etape-2.png",
        alt: "Screenshot 2 - web app Extracadabra",
      },
      {
        src: "/img/candidats/etape-3.png",
        alt: "Screenshot 3 - web app Extracadabra",
      },
    ],
  },
};
