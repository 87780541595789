import type { $Fetch, FetchOptions } from "ofetch";
import { stringify } from "qs";

interface HttpFactoryCall {
  method: "GET" | "POST" | "PUT";
  url: string;
  data?: object;
  fetchOptions?: FetchOptions<"json">;
}

class HttpFactory {
  public $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  /**
   * method - GET, POST, PUT
   * URL
   **/
  async call<T>({ method, url, data, fetchOptions }: HttpFactoryCall): Promise<T> {
    return await this.$fetch<T>(url, {
      method,
      body: data,
      ...fetchOptions,
      onRequest({ options }) {
        if (options.params) {
          const convertedOptions = new URLSearchParams(stringify(options.params));
          options.params = Object.fromEntries(convertedOptions);
        }
      },
    });
  }
}

export default HttpFactory;
