import type { AsyncDataOptions } from "#app";
import HttpFactory from "~/repository/factory";
import type { APIError } from "~/models/Api";
import type { StrapiFile } from "~/models/strapi/Strapi";

type LeadGenDataRegisterBody = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  salesEmailAddress?: string;
  phone: string;
  postalCode: string;
  cardNumber: string;
  howManyEmployee: string;
  segmentation: string;
  slug: string;
};

type LeadGenDataRegister = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  salesEmailAddress?: string;
  phone: string;
  postalCode: string;
  cardNumber: string;
  howManyEmployee: string;
  segmentation: string;
  file: StrapiFile;
};

class LeadGenDataModule extends HttpFactory {
  private resourcePath = "/api/lead-gen-datas";

  async register(
    {
      firstName,
      lastName,
      company,
      email,
      salesEmailAddress,
      phone,
      postalCode,
      cardNumber,
      howManyEmployee,
      segmentation,
      slug,
    }: LeadGenDataRegisterBody,
    asyncDataOptions?: AsyncDataOptions<LeadGenDataRegister>,
  ) {
    return await useAsyncData<LeadGenDataRegister, { data: APIError }>(
      email,
      () => {
        const url = `${this.resourcePath}/register`;
        return this.call({
          method: "POST",
          url,
          data: {
            firstName,
            lastName,
            company,
            email,
            salesEmailAddress,
            phone,
            postalCode,
            cardNumber,
            howManyEmployee,
            segmentation,
            slug,
          },
        });
      },
      asyncDataOptions,
    );
  }
}

export default LeadGenDataModule;
