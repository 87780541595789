import type { AsyncDataOptions } from "nuxt/app";
import type { FetchError } from "ofetch";
import HttpFactory from "../../factory";
import type { APIParams, APIResponse } from "~/models/Api";
import type { FaqItem, FaqItemWithGroups, FaqType } from "~/models/Faq";

class FaqsModule extends HttpFactory {
  private resourcePath = "/api/faqs";

  async list(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<FaqType[]>>,
  ) {
    return await useAsyncData<APIResponse<FaqType[]>>(
      "faqs",
      () => {
        const params = {
          populate: {
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async getFaqsByGroup(
    { group }: { group: string | Ref<string> },
    asyncDataOptions?: AsyncDataOptions<APIResponse<FaqItem[]>>,
  ) {
    return await useAsyncData<APIResponse<FaqItem[]>>(() => {
      const params = {
        populate: {
          groups: true,
        },
        filters: {
          groups: {
            slug: isRef(group) ? group.value : group,
          },
        },
      };

      return this.call({
        method: "GET",
        url: this.resourcePath,
        fetchOptions: {
          params,
        },
      });
    }, asyncDataOptions);
  }

  async findOne<T = FaqItemWithGroups, R = T>(
    { slug }: { slug: string | Ref<string> },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(() => {
      const url = `${this.resourcePath}/${slug}`;

      return this.call({
        method: "GET",
        url,
      });
    }, asyncDataOptions);
  }
}

export default FaqsModule;
