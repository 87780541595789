import { routes } from "~/constants";
export const otherCities = {
  candidates: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: `${routes.candidatUrl.index}/${routes.candidatUrl.hidfLyon}`,
        label: "EN SAVOIR PLUS",
      },
      title:
        "Trouvez les meilleures missions en restauration\nà <strong>Lyon</strong> avec <strong>Extracadabra</strong>",
      description:
        "Rejoins notre communauté lyonnaise et reçois les meilleures offres de prestations en salle, cuisine ou vente près de chez toi !",
    },
  ],
  recruteurs: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: `${routes.recruteurUrl.index}/${routes.recruteurUrl.lpEtabLyon}`,
        label: "EN SAVOIR PLUS",
      },
      title:
        "En recherche de <strong>personnel qualifié</strong> à <strong>Lyon</strong> ?",
      description:
        "Vous êtes un professionnel de l’Hôtellerie, Restauration ou de la Vente ? Nous vous mettons en relation avec nos candidats indépendants en quelques heures.",
    },
  ],
};
