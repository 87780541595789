<script setup lang="ts">
const { t } = useI18n();

const props = defineProps<{
  error: {
    url: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    description: string;
    data: unknown;
  };
  clearError?: (options: { redirect: string }) => void;
}>();

useHead({
  title: t("ERROR.SEO.@TITLE", { code: props.error.statusCode }),
  meta: [
    {
      hid: "description",
      name: "description",
      content: t("ERROR.SEO.@DESCRIPTION"),
    },
  ],
});

const characterImages = ["/img/character-error-1.webp", "/img/character-error-2.webp"];

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * characterImages.length);
  return characterImages[randomIndex];
};

const currentCharacterImage = computed(() => getRandomImage());

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div>
    <NuxtLayout class="default-main--error">
      <div class="c-error">
        <h1 class="c-error__title">
          {{ $t("ERROR.@TITLE", { code: error.statusCode }) }}
        </h1>
        <p class="c-error__description">{{ $t("ERROR.@DESCRIPTION") }}</p>
        <div class="c-error__illustration c-error-illustration">
          <img
            :src="currentCharacterImage"
            alt="Error"
            class="c-error-illustration__image"
          />
        </div>
        <Button @click="handleError">
          <SvgoIconArrowLeft />
          {{ $t("ERROR.@BUTTON") }}
        </Button>
      </div>
    </NuxtLayout>

    <div id="portal-popup"></div>
  </div>
</template>

<style lang="scss">
.default-main--error {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  &::before,
  &::after {
    --_background-size: 40%;
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    width: 664px;
    height: 505px;
    background-repeat: no-repeat;
    background-size: var(--_background-size);

    @include mq($from: tablet) {
      --_background-size: 60%;
    }

    @include mq($from: desktop) {
      --_background-size: 100%;
    }
  }

  &::before {
    left: 0;
    background-image: url("/img/partenaires-habillage.png");
    background-position: top left;
  }

  &::after {
    right: 0;
    background-image: url("/img/partenaires-habillage-2.png");
    background-position: top right;
  }
}

.footer {
  margin-top: auto;
}

.c-error {
  --_title-fs: 32px;
  --_description-fs: 14px;
  --_margin-top: 20px;
  --_title-margin-bottom: 12px;

  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: var(--_margin-top) auto 80px;

  @include mq($from: tablet) {
    --_title-fs: 42px;
    --_description-fs: 18px;
    --_margin-top: 80px;
    --_title-margin-bottom: 28px;
  }

  &__title,
  &__description {
    margin-bottom: var(--_title-margin-bottom);
  }

  &__title {
    text-transform: uppercase;
    font-size: var(--_title-fs);
    color: var(--showcase-color-primary-200);
    font-weight: 700;
  }

  &__description {
    font-size: var(--_description-fs);
    line-height: 1.2em;
    color: var(--showcase-color-additional-dark);
  }

  &__illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
    max-width: 510px;
  }
}

.c-error-illustration {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    max-height: 50%;
    width: 100%;
    display: block;
    background: var(--showcase-color-primary-200);
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  }

  &__image {
    position: relative;
    z-index: 1;
    height: auto;
    max-width: 70%;
  }

  @include mq($from: tablet) {
    &__image {
      max-width: none;
    }
  }
}
</style>
