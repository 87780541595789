import type { FetchError } from "ofetch";
import type { AsyncDataOptions } from "nuxt/app";
import HttpFactory from "../../factory";
import type { APIParams, APIResponse } from "~/models/Api";
import type { FaqGroup, FaqType, FaqTypes } from "~/models/Faq";

class FaqGroupsModule extends HttpFactory {
  private resourcePath = "/api/faq-groups";

  async list(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<FaqType[]>>,
  ) {
    return await useAsyncData<APIResponse<FaqType[]>>(
      "faqGroups",
      () => {
        const params = {
          populate: {
            type: true,
            faqs: true,
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async getGroupsByType<T = FaqGroup[], R = T>(
    { type }: { type: FaqTypes },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      `faqGroupsByType::${type}`,
      () => {
        const url = `${this.resourcePath}/${type}`;

        return this.call({
          method: "GET",
          url,
        });
      },
      asyncDataOptions,
    );
  }
}

export default FaqGroupsModule;
