export default defineNuxtPlugin(() => {
  if (process.client) {
    const searchParams: string = window.location.search;
    if (searchParams.length > 0) {
      localStorage.setItem("utmParams", searchParams);
    } else {
      localStorage.removeItem("utmParams");
    }
  }
});
