import { routes } from "~/constants";
export const cafeRestaurant = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/gqVHDtie",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 entreprises",
    title3: "nous font confiance !",
    subtitle: "Restaurants, Cafés, Bars…",
  },
  qualifications: {
    title: "Ensemble, trouvons les meilleurs profils pour renforcer vos équipes",
    list: [
      {
        qualificationCategory: "Salle",
        qualificationImgSrc:
          "/img/recruteurs/bu/cafe-restaurant/qualification-salle.jpeg",
        qualificationList: [
          {
            name: "Runner",
            slug: "runner",
            informations: {
              definition: {
                title:
                  "Quelles sont les tâches et l'importance du Runner en restauration ?",
                text: "Le <strong>runner</strong> travaille en salle sous les ordres du chef de rang, il apporte les plats aux clients et débarrasse les tables. Très actif, il fait le lien entre la salle et la cuisine. Les missions principales du runner sont donc de nettoyer le restaurant, dresser les tables, et servir les clients. Actuellement, <strong>plus de 2 000 runners sont disponibles</strong> dans notre vivier de candidats à <strong>Paris et à Lyon.</strong>",
              },
            },
          },
          {
            name: "Serveur",
            slug: "serveur",
            informations: {
              definition: {
                title:
                  "Quel est le rôle et les responsabilités d'un Serveur en restauration ?",
                text: "Le <strong>serveur</strong> prend les commandes et sert les plats et les boissons aux clients. Ses missions principales sont de se charger d’accueillir la clientèle, d'offrir un excellent service pour assurer la satisfaction des clients, et de prendre les commandes. Le serveur doit également être dans la capacité de pouvoir recommander des plats, répondre aux questions et donner des informations supplémentaires aux clients. Il doit aussi se charger de l’encaissement. Actuellement des <strong>milliers de serveurs sont disponibles à Paris ou à Lyon</strong> au sein de notre communauté.",
              },
            },
          },
          {
            name: "Chef de rang",
            slug: "chef-de-rang",
            informations: {
              definition: {
                title: "En quoi consiste le travail d'un Chef de Rang en restauration ?",
                text: "Responsable d’un groupe de tables, le <strong>chef de rang</strong> doit veiller au bon déroulement du service dans le restaurant sur l’ensemble des tables qui lui sont attribuées. Sous la responsabilité du maître d’hôtel ou du responsable de salle, il supervise les serveurs et runners positionnés sur son rang. Il doit développer une bonne relation avec la clientèle, connaître parfaitement la carte du restaurant et les plats proposés afin de répondre aux questions et conseiller la clientèle. Avant l'arrivée des clients, le chef de rang veille à la préparation des tables, la mise en place du service, afin de pouvoir accueillir et accompagner les clients. Le chef de rang prend les commandes, sert et encaisse les clients.",
              },
            },
          },
          {
            name: "Maître d’hôtel",
            slug: "maitre-d-hotel",
            informations: {
              definition: {
                title:
                  "Quelles sont les fonctions et l'importance du Maître d’Hôtel en restauration ?",
                text: "Le <strong>maître d’hôtel (ou chef de salle)</strong> est responsable de l’accueil du client : il place le client à sa table, présente la carte et les menus, émet des suggestions, notamment sur les accords des mets et des vins. Il doit aussi organiser la répartition des tâches en salle et le contrôle du déroulement des opérations pendant le service. Le maître d’hôtel orchestre minutieusement le travail des commis de salle, des chefs de rang et sommeliers afin de garantir le bon déroulement et l'excellence du service en restaurant ou dans l’établissement au sein duquel il officie.",
              },
            },
          },
          {
            name: "Responsable de salle",
            slug: "responsable-de-salle",
            informations: {
              definition: {
                title:
                  "Quelles sont les compétences clés pour un Responsable de Salle en restauration ?",
                text: "Tel un chef d'orchestre, le <strong>responsable de salle</strong> en restauration coordonne l'équipe de serveurs avec efficacité, s'assurant que chaque client reçoit une attention personnalisée et que l'ambiance du restaurant reste agréable et accueillante. Ce rôle exige un équilibre parfait entre le souci du détail, une bonne communication et une capacité à résoudre rapidement les problèmes, contribuant ainsi à une expérience de restauration magique !",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Cuisine",
        qualificationImgSrc:
          "/img/recruteurs/bu/cafe-restaurant/qualification-cuisine.jpeg",
        qualificationList: [
          {
            name: "Plongeur",
            slug: "plongeur",
            informations: {
              definition: {
                title:
                  "Quel est le rôle essentiel du Plongeur dans une cuisine de restaurant ?",
                text: "Le <strong>plongeur</strong> est un maillon essentiel pour maintenir un excellent niveau de propreté en cuisine. Il est en charge du nettoyage de la vaisselle, ustensiles et équipements de cuisine. Il participe au maintient de l’hygiène et de l’ordre dans l’espace de travail de la brigade. Le plongeur peut aussi aider à la préparation des aliments et à la gestion des déchets, selon les besoins. Des milliers de plongeurs qualifiés sont disponibles chez Extracadabra.",
              },
            },
          },
          {
            name: "Commis de cuisine",
            slug: "commis-de-cuisine",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités d'un Commis de Cuisine dans un restaurant ?",
                text: "Dans le tourbillon de la cuisine, le commis est celui qui jongle entre épluchage, découpage, et préparation, tout en apprenant les secrets des chefs. Le <strong>commis de cuisine</strong> est à la fois un élève et un élément clef dans la brigade d’un restaurant. Sans lui, pas d’expérience culinaire mémorable ! Des milliers de commis de cuisine qualifiés sont disponibles chez Extracadabra.",
              },
            },
          },
          {
            name: "Chef de partie",
            slug: "chef-de-partie",
            informations: {
              definition: {
                title:
                  "En quoi consiste le rôle d'un Chef de Partie dans la cuisine d'un restaurant ?",
                text: "Un <strong>chef de partie</strong> est un peu comme un chef d'orchestre au sein de la cuisine du restaurant. Il doit réaliser les plats, choisir les produits qui serviront à leur élaboration, gérer la mise en place du service... Le chef de partie doit également appliquer et faire appliquer les règles d'hygiène en cuisine. Il doit posséder des qualités techniques mais aussi des qualités relationnelles afin de former les commis et collaborer avec le service en salle.",
              },
            },
          },
          {
            name: "Chef",
            slug: "chef",
            informations: {
              definition: {
                title:
                  "Quelles sont les missions principales d'un Chef de Cuisine dans un restaurant ?",
                text: "Le <strong>chef de cuisine</strong> coordonne, dirige et supervise l’ensemble de l’activité de la cuisine. Ce type de poste nécessite une expérience confirmée, c'est avant tout un cuisinier avéré. Les missions principales du chef de cuisine sont l’approvisionnement de la cuisine en produits, viandes, condiments... et l'organisation des différentes tâches de la brigade. Il s'occupe également de l'analyse du coût des recettes, du management des équipes, ainsi que du contrôle du respect des règles d’hygiène.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Bar",
        qualificationImgSrc: "/img/recruteurs/bu/cafe-restaurant/qualification-bar.jpeg",
        qualificationList: [
          {
            name: "Limonadier",
            slug: "limonadier",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités et le rôle d'un Limonadier dans un bar ?",
                text: "Le rôle principal du <strong>limonadier</strong> est de préparer et servir une variété de boissons, y compris des boissons sans alcool, des jus de fruits frais, et des cafés, en respectant les normes de qualité et de présentation. Le poste exige un goût pour le service client, une connaissance approfondie des recettes des boissons, et la capacité de travailler dans un environnement dynamique.",
              },
            },
          },
          {
            name: "Barista",
            slug: "barista",
            informations: {
              definition: {
                title:
                  "Quel est l'impact d'un Barista sur l'expérience client dans un café ?",
                text: "Le <strong>barista</strong>, maître de l'art du café, est essentiel derrière le comptoir d’un bon café. Avec une expertise fine des différents grains de café, méthodes de torréfaction et techniques de préparation, le barista ne se contente pas de préparer des expressos et des lattes, il excelle dans la maîtrise de la mousse de lait et la présentation soignée. Son rôle va au-delà de la simple préparation de boissons ; il crée une atmosphère conviviale et accueillante, faisant du café un moment de détente et de plaisir dans la journée des clients.",
              },
            },
          },
          {
            name: "Barman",
            slug: "barman",
            informations: {
              definition: {
                title:
                  "Quelles sont les compétences et le rôle d'un Barman dans un bar ou restaurant ?",
                text: "Le rôle principal d'un <strong>barman</strong> est de créer et préparer des cocktails. Véritable artiste de la mixologie, il participe à la création d’une expérience mémorable pour chaque client. En fonction de son lieu de travail (brasserie, club, bars de grands hôtels…) son rôle peut varier et il sera par exemple en charge d’assurer le service, gérer les stocks de boissons, créer la carte des cocktails en plus de la réalisation des cocktails. Les meilleurs Barmans sont dans la communauté Extracadabra !",
              },
            },
          },
          {
            name: "Mixologue",
            slug: "mixologue",
            informations: {
              definition: {
                title:
                  "Qu'est-ce qui distingue un Mixologue dans l'art de la mixologie ?",
                text: "Le <strong>mixologue</strong> est un créateur de cocktails et un expert en saveurs. Dans l'univers des bars et lounges, il élève l'art du cocktail à de nouveaux sommets avec une combinaison audacieuse d'ingrédients, de techniques et de présentation. Cet expert des boissons mélange tradition et innovation, utilisant son savoir-faire pour concevoir des goûts uniques. Que ce soit en revisitant des classiques ou en créant des compositions originales, le mixologue sait équilibrer les saveurs pour offrir une expérience gustative exceptionnelle. Il conseille les clients, partage des histoires sur les origines des boissons, et crée une ambiance dynamique et engageante autour du bar.",
              },
            },
          },
          {
            name: "Sommelier",
            slug: "sommelier",
            informations: {
              definition: {
                title:
                  "Comment un Sommelier contribue-t-il à l'expérience culinaire dans un restaurant ?",
                text: "Le <strong>sommelier</strong>, dans l'univers de la restauration, est un expert passionné et éclairé en vins et spiritueux. Sa mission est de guider les clients à travers une expérience œnologique enrichissante, assortissant les vins aux plats pour magnifier les saveurs. Doté d'une connaissance approfondie des cépages, des régions viticoles et des techniques de vinification, le sommelier partage son savoir. Il écoute les préférences des clients, suggère des accords mets-vins harmonieux et introduit des sélections inattendues pour mener à la découverte. Le sommelier est aussi responsable de la gestion de la cave du restaurant, veillant à la qualité et à la diversité des vins proposés. ",
              },
            },
          },
        ],
      },
    ],
  },
  verbatims: {
    title: "Nos clients en restauration en parlent mieux que nous…",
  },
  faqs: {
    title: "Recruteurs : Vos questions, nos réponses",
    list: [
      {
        question: "Combien coûte un Extra ?",
        answer:
          "Chez Extracadabra, le coût d'un extra ou renfort en restauration est fixe et sans surprise. Serveur, cuisinier, plongeur, chef de rang… En fonction de la qualification que vous recherchez, le tarif de la prestation sera ajusté. Vous trouverez par exemple, votre futur serveur pour 21,50 euros de l’heure ou votre chef de partie pour 23,50 euros de l’heure.",
      },
      {
        question: "Comment travailler avec Extracadabra ?",
        answer:
          "Extracadabra, c'est simple comme un claquement de doigt ! En plus de notre plateforme en ligne, vous pouvez prendre contact directement par téléphone pour un accompagnement personnalisé. Vous obtiendrez une réponse à toutes les questions que vous vous posez quels que soient vos besoins. Autrement, il ne vous reste plus qu'à créer votre compte sans engagement. Après quelques questions et 5 minutes de votre temps, votre compte sera créé et vous pourrez poster votre première demande gratuitement pour trouver le talent qu'il vous faut !",
      },
      {
        question: "Dans quelles régions Extracadabra est-il présent ?",
        answer:
          "Extracadabra a un vivier de professionnels spécialisés dans les métiers de la restauration à Paris et à Lyon. Pour des demandes en dehors de ces zones, n’hésitez pas à vous rapprocher de nos équipes.",
      },
      {
        question: "Quelles sont les qualifications proposées par Extracadabra ? ",
        answer:
          "Chez Extracadabra, notre vivier de candidats est riche et surtout expert dans de nombreux domaines : maître d’hôtel, serveurs, mixologue, barman, commis de bar, chef de partie, commis de cuisine, chef de rang… Vous trouverez forcément le profil idéal pour renforcer votre équipe en salle ou cuisine !",
      },
    ],
  },
  otherCities: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: "#",
        label: "EN SAVOIR PLUS",
      },
      title: "Trouvez les meilleurs talents <strong>à Lyon</strong> avec Extracadabra",
      description:
        "Confiez-nous vos besoins de recrutement à Lyon et bénéficiez de notre expertise pour dénicher les candidats les plus qualifiés",
    },
  ],
  leadGen: {
    book: {
      src: "/img/recruteurs/book_lead_gen.png",
      alt: "illustration du e-book Extracadabra",
    },
  },
  footer: {
    legals: {
      cancelation: `*Si vous n’annulez pas une prestation moins de 72 heures avant. <a href='${routes.cgu}'>en savoir plus</a>`,
    },
  },
};
