import type { AsyncDataOptions } from "#app";
import HttpFactory from "~/repository/factory";
import type { SliderArticles } from "~/models";
import type { APIParams, APIResponse } from "~/models/Api";

class SliderArticlesModule extends HttpFactory {
  private resourcePath = "/api/slider-article";

  async list(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<SliderArticles>>,
  ) {
    return await useAsyncData<APIResponse<SliderArticles>>(
      "sliderArticles",
      () => {
        const params = {
          populate: {
            articles: {
              populate: {
                banner: true,
                tags: true,
              },
            },
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }
}

export default SliderArticlesModule;
