<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    loading?: boolean;
    disabled?: boolean;
    ctaType?: "default" | "dark-blue" | "recruteur";
  }>(),
  {
    loading: false,
    disabled: false,
    ctaType: "default",
  },
);

const slots = useSlots();

const isDisabled = computed(() => props.loading || props.disabled);
</script>

<template>
  <button
    class="c-button"
    :class="[{ 'c-button--loading': loading }, ctaType ? `c-button--${ctaType}` : '']"
    :disabled="isDisabled"
  >
    <span v-if="slots.startIcon" class="c-button__icon">
      <slot name="startIcon"></slot>
    </span>
    <slot></slot>
  </button>
</template>

<style lang="scss">
.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 10px 16px;
  border-radius: 50px;
  min-height: 50px;
  background: $primary-color;
  color: $white-extra;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  position: relative;
  border: 1px solid transparent;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition:
    background-color 250ms ease-in,
    border 250ms ease-in,
    color 250ms ease-in;
  &:hover:not(.c-button--loading),
  &:focus-visible:not(.c-button--loading),
  &--loading {
    border-color: inherit;
    background: $white-extra;
    color: $primary-color;
  }

  .nuxt-icon {
    width: 24px;
    height: auto;
    margin: 0;
  }

  &--loading {
    border-color: transparent;
    .nuxt-icon {
      width: 28px;
      animation: loadingButton 2s infinite linear;
    }
  }

  @keyframes loadingButton {
    0% {
      transform: rotate(deg) scale(1);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }
  &--dark-blue {
    background-color: var(--showcase-color-secondary-300);
    min-height: 0;
    width: 100%;
    @include mq(desktop) {
      max-height: 44px;
    }
  }
  &--recruteur {
    background-color: var(--showcase-color-secondary-200);
    min-height: 0;
    @include mq(desktop) {
      max-height: 44px;
    }
    &:hover:not(.c-button--loading),
    &:focus-visible:not(.c-button--loading) {
      border-color: var(--showcase-color-secondary-200);
      color: var(--showcase-color-secondary-200);
    }
  }
  &.lp-etab-lyon {
    min-height: 50px;
  }
}
</style>
