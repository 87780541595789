import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();
  const SENTRY_DSN = config.public.sentryDSN;
  const SENTRY_ENVIRONMENT = config.public.sentryEnvironment;
  const APP_VERSION = config.public.appVersion;
  const NODE_ENV = process.env.NODE_ENV;

  if (!SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.warn("Sentry DSN not set, skipping Sentry initialization");
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: SENTRY_DSN,
    enabled: NODE_ENV === "production",
    environment: SENTRY_ENVIRONMENT,
    release: `showcase-client@${APP_VERSION}-${SENTRY_ENVIRONMENT}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 0.5,
  });
});
