import { restaurationCollective } from "./restaurationCollective";
import { hotellerie } from "./hotellerie";
import { cafeRestaurant } from "./cafeRestaurant";
import { traiteurEvenementiel } from "./traiteurEvenementiel";
import { commerceDeBouche } from "./commerceDeBouche";
import { logistiqueVente } from "./logistiqueVente";
import { hidfLyon } from "./hidfLyon";

export const bu = {
  restaurationCollective,
  hotellerie,
  cafeRestaurant,
  traiteurEvenementiel,
  commerceDeBouche,
  logistiqueVente,
  hidfLyon,
};
