import { routes } from "~/constants";
export const HOME = {
  // HUBCLIENTS
  "@HOME_BANNER_HUB_CANDIDAT_TITLE": "ESPACE CANDIDAT",
  "@HOME_BANNER_HUB_CANDIDAT_SUBTITLE_PART1": "TROUVER ",
  "@HOME_BANNER_HUB_CANDIDAT_SUBTITLE_PART2": "UN JOB",
  "@HOME_BANNER_HUB_CANDIDAT_CTA_LABEL": "TRAVAILLER",
  "@HOME_BANNER_HUB_RECRUTEUR_TITLE": "ESPACE RECRUTEUR",
  "@HOME_BANNER_HUB_RECRUTEUR_SUBTITLE_PART1": "RENFORCER ",
  "@HOME_BANNER_HUB_RECRUTEUR_SUBTITLE_PART2": "VOS &Eacute;QUIPES",
  "@HOME_BANNER_HUB_RECRUTEUR_CTA_LABEL": "RECRUTER",
  "@HOME_BANNER_HUB_TEXT_BOTTOM":
    "La seule plateforme pour tous vos besoins de personnel ou de job, court ou long terme",
  // PARTNERS
  "@HOME_PARTNERS_TITLE_PART1": "Plus de",
  "@HOME_PARTNERS_TITLE_PART2": "10 000 entreprises",
  "@HOME_PARTNERS_TITLE_PART3": "nous font confiance !",
  "@HOME_PARTNERS_SUBTITLE": "Restaurants, Bars, Hôtels, Magasins, Entrepôts…",
  // CLIENT PUSH
  "@HOME_PUSH_RECRUTEUR_TITLE": "Renforcez votre effectif en un claquement de doigt",
  "@HOME_PUSH_RECRUTEUR_TEXT":
    "CDI, Extra, ou Intérim, publiez votre annonce & recevez des candidatures de qualité en 3 min ! Notre algorithme vous propose le meilleur profil au bon moment.",
  "@HOME_PUSH_RECRUTEUR_CTA_LABEL": "ESPACE RECRUTEUR",
  "@HOME_PUSH_APP_RECRUTEUR_TITLE":
    "Téléchargez aussi l’application dédiée au recrutement",
  "@HOME_PUSH_APP_RECRUTEUR_PLAY_STORE_URL":
    "https://play.google.com/store/apps/details?id=com.extracadabra.android.etab",
  "@HOME_PUSH_APP_RECRUTEUR_APPLE_STORE_URL":
    "https://apps.apple.com/fr/app/extracadabra-etablissement/id1056907780",
  "@HOME_PUSH_APP_RECRUTEUR_PICTO": "/img/picto-app-candidat.png",
  "@HOME_PUSH_CANDIDAT_TITLE": "Travaillez au rythme de vos envies",
  "@HOME_PUSH_CANDIDAT_TEXT":
    "CDI, Intérim ou Extra, arrêtez de chercher & recevez les offres qui vous correspondent vraiment.",
  "@HOME_PUSH_CANDIDAT_CTA_LABEL": "ESPACE CANDIDAT",
  "@HOME_PUSH_APP_CANDIDAT_TITLE": "Téléchargez l’application dédiée à l’emploi",
  "@HOME_PUSH_APP_CANDIDAT_PLAY_STORE_URL":
    "https://play.google.com/store/apps/details?id=com.extracadabra.android.extra",
  "@HOME_PUSH_APP_CANDIDAT_APPLE_STORE_URL":
    "https://apps.apple.com/fr/app/extracadabra-etablissement/id1056999788",
  "@HOME_PUSH_APP_CANDIDAT_PICTO": "/img/picto-app-candidat.png",
  // PUSH RESSOURCES
  "@HOME_PUSH_RESSOURCES": [
    {
      name: "jobs",
      description:
        "Travaille où tu veux et à ton rythme, des centaines d’offres d’emploi près de chez toi chaque jour.",
      imgDesktop: "/img/jobs-desktop.png",
      imgMobile: "/img/jobs-mobile.png",
      url: routes.jobsUrl.index,
      external: true,
      label: "Trouvez un job",
    },
    {
      name: "blog",
      description:
        "Articles, conseils, études et actualités, tout ce qu’il faut savoir sur le secteur de l’emploi avec Extracadabra !",
      imgDesktop: "/img/blog-desktop.png",
      imgMobile: "/img/blog-mobile.png",
      url: routes.blogHomeUrl,
      label: "Découvrez",
    },
  ],
  // VERBATIMS
  "@HOME_VERBATIM_RECRUTEUR": {
    title: "Témoignages de nos clients",
    verbatims: [
      {
        photoSrc: "/img/anthony-rachels.png",
        logoSrc: "/img/rachels-logo.png",
        logoAlt: "Logo Rachel's",
        photoAlt: "Photo de Anthony de Rachel's",
        quote:
          "“ La cohésion d'équipe, en termes de souplesse et de réactivité. Extracadabra m'a beaucoup accompagné lors de gros rushs. Je vais sur l'application, en deux heures, même en une heure, il m'est arrivé d'avoir quelqu'un. Ça permet de relâcher la pression au niveau des équipes, de limiter les heures supplémentaires qui peuvent vite être stressantes et de continuer la semaine dans des bonnes conditions“",
        author: "Anthony, Rachel’s",
      },
      {
        photoSrc: "/img/ludivine-serenest.png",
        logoSrc: "/img/serenest-logo.png",
        logoAlt: "Logo Serenest",
        photoAlt: "Photo de Ludivine de Serenest",
        quote:
          "“ Extracadabra m'a beaucoup accompagné lors de gros rushs. Je vais sur l'application, en deux heures, même en une heure, il m'est arrivé d'avoir quelqu'un.<br /><br />Ça permet de relâcher la pression au niveau des équipes, de limiter les heures supplémentaires qui peuvent vite être stressantes !“",
        author: "Ludiwine, Serenest",
      },
      {
        photoSrc: "/img/frederic-deux-magots.png",
        logoSrc: "/img/les-deux-magot-logo.png",
        logoAlt: "Logo des Deux Magots",
        photoAlt: "Photo de Frédéric des Deux Magots",
        quote:
          "“ Aujourd'hui on a plus qu'à faire presque des clics boutons pour voir passer une offre et pour pouvoir recruter dans l'application. Ensuite,) Ce qui est agréable, c'est qu'on a accès à tous les profils intéressés et ça nous permet de les sélectionner. Rapide, fonctionnel, pratique“",
        author: "Frédéric, Les Deux Magots",
      },
    ],
  },
  "@HOME_VERBATIM_CANDIDAT": {
    title: "Témoignages de nos extraordinaires",
    verbatims: [
      {
        quote:
          "“ L’application est vraiment simple d’utilisation. Ca me permet de passer de restaurant en restaurant pour voir ce qui me correspond le plus car j’aime bien bouger. Les missions tombent toutes seules c’est génial ! “",
        author: "Seraphin, 30 ans, Chef de rang",
      },
      {
        quote:
          "“ Cela fait quelques mois que j’utilise l’app, je cherchais à être plus indépendante. Extracadabra me donne beaucoup de liberté, je choisis où et quand je veux travailler. J’accepte les missions que je veux, ça me permet d’être flexible. Il y a aussi un très bon suivi après ta mission“",
        author: "Chloé, 27 ans, Chef de partie",
      },
      {
        quote:
          "“ Que des compliments me viennent à l'esprit au sujet d'Extracadabra, l'application est simple et facile d'utilisation et l'équipe est formidable, toujours présente et disponible pour me renseigner ou soutenir si besoin et dans la bonne humeur.<br />Aussi, en tant qu'intérimaire, les missions proposées me donnent la chance de travailler dans de belles maisons.<br />10/10, facile.“",
        author: "William, 56 ans, Cuisinier",
      },
    ],
  },
  // OUR TEAM
  "@HOME_OUR_TEAM": {
    title: "L'équipe Extracadabra",
    description:
      "Entreprises ou candidats, Extracadabra vous garantie le perfect match ! Plus besoin de baguette magique pour trouver le job idéal et le candidat rêvé. Créé en 2015 par Frédéric Nardon et Rémi Boisson, Extracadabra est une application qui met en relation des candidats avec des établissements grâce à un système de matching hors du commun. Grâce à Extracadabra, le candidat a toujours le choix entre une sélection d’offres de missions ponctuelles (Extra ou Intérim) ou CDI et les établissements peuvent choisir le candidat qui leur correspond.",
    imgDesktop: "/img/photo-team-extracadabra-desktop.jpg",
    imgMobile: "/img/photo-team-extracadabra.jpg",
    imgAlt: "Photo de l'équipe Extracadabra",
  },
  // PRESS
  "@HOME_PRESS_TITLE": "La presse en parle",
  "@HOME_PRESS": [
    {
      src: "/img/presse/logo-ACDC.svg",
      alt: "Logo au coeur du CHR",
      url: "https://aucoeurduchr.fr/article/emploi-formation/resoudre-le-casse-tete-du-recrutement/",
    },
    {
      src: "/img/presse/logo-lsa.webp",
      alt: "Logo de LSA",
      url: "https://www.lsa-conso.fr/food-tech-on-connait-les-start-up-de-la-premiere-promotion-de-smart-food-paris,240072",
    },
    {
      src: "/img/presse/logo-jdn.webp",
      alt: "Logo du Journal du net",
      url: "https://www.journaldunet.com/web-tech/start-up/1178480-confidentiel-les-fondateurs-de-lafourchette-et-doctolib-investissent-dans-extracadabra/",
    },
    {
      src: "/img/presse/logo-alimentation-general.webp",
      alt: "Logo de Alimentation General",
      url: "https://alimentation-generale.fr/chronique/smartfood-paris-la-premiere-promo/",
    },
    {
      src: "/img/presse/logo-le-parisien.webp",
      alt: "Logo du journal le Parisien",
      url: "https://www.leparisien.fr/economie/business/emplois-saisonniers-lhotellerie-restauration-a-la-cote-08-06-2021-ULXYPWKRRBESFORCHO24XFDN7U.php",
    },
    {
      src: "/img/presse/logo-l-usine-digitale.webp",
      alt: "Logo de l'usine digitale",
      url: "https://www.usine-digitale.fr/article/french-tech-11-start-up-ont-leve-15-millions-d-euros-cette-semaine.N392337",
    },
    {
      src: "/img/presse/logo-les-echos-entrepreneurs.webp",
      alt: "Logo des echos entrepreneur",
      url: "https://entrepreneurs.lesechos.fr/creation-entreprise/reseaux-accompagnement/paris-veut-peser-sur-lavenir-du-secteur-alimentaire-1989413",
    },
  ],
};
