import type { FetchError } from "ofetch";
import type { AsyncDataOptions } from "#app";
import type { APIParams, APIParamsPagination, APIResponse } from "~/models/Api";
import type { Tag } from "~/models/Tag";
import HttpFactory from "~/repository/factory";

class TagsModule extends HttpFactory {
  private resourcePath = "/api/tags";

  async list<T = APIResponse<Tag[]>>(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<T>,
  ) {
    return await useAsyncData<T>(
      "tags",
      () => {
        const params = {
          populate: {
            banner: true,
            articles: true,
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async findOneBySlug<T = Tag, R = T>(
    {
      slug,
    }: {
      slug?: Ref<string> | string;
    },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      "tagBySlug",
      () => {
        const url = `${this.resourcePath}/${slug}`;

        return this.call({
          method: "GET",
          url,
        });
      },
      asyncDataOptions,
    );
  }

  async getBySlugs(
    {
      slugs,
      pagination,
    }: {
      slugs?: Ref<string[]>;
      pagination?: APIParamsPagination;
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<Tag[]>>,
  ) {
    return await useAsyncData<APIResponse<Tag[]>>(
      "tagsByArticleSlugs",
      () => {
        const params = {
          populate: {
            banner: true,
            tags: true,
          },
          filters: {
            tags: {
              slug: {
                $in: slugs?.value ?? [],
              },
            },
          },
          pagination,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }
}

export default TagsModule;
