import {
  utm,
  partnerItem,
  mobileAppSlider,
  pushCreateAccount,
  accordion,
  pushMobileApp,
  needHelp,
  infosTextWithLogo,
  pushExtracadabraProducts,
  pushQualificationBu,
  heroBanner,
  socialLinks,
  press,
  ourTeam,
  pushTuturialVideo,
  pushRessources,
  applications,
  popups,
  bu,
  footer,
  otherCities,
  faqLanding,
  faqHome,
  landings,
  hubClients,
  forms,
  simulatorCaAe,
  menu,
  banner,
} from "~/config/app";

export default defineAppConfig({
  popups,
  applications,
  pushRessources,
  pushTuturialVideo,
  ourTeam,
  press,
  socialLinks,
  heroBanner,
  pushQualificationBu,
  pushExtracadabraProducts,
  infosTextWithLogo,
  needHelp,
  pushMobileApp,
  accordion,
  pushCreateAccount,
  mobileAppSlider,
  partnerItem,
  utm,
  bu,
  footer,
  otherCities,
  faqLanding,
  faqHome,
  landings,
  hubClients,
  forms,
  simulatorCaAe,
  menu,
  banner,
});
