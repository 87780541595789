export const pushCreateAccount = {
  candidates: [
    {
      url: "https://candidat.extracadabra.com/#/creer-un-compte",
      picto: "extra-logo",
    },
    {
      url: "https://candidat.extracadabra.com/#/se-connecter",
      link: true,
    },
  ],
  etab: [
    {
      url: "https://etablissement.extracadabra.com/#/auth/signup",
      picto: "extra-logo",
    },
    {
      url: "https://etablissement.extracadabra.com/#/auth/login",
      link: true,
    },
  ],
};
