export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return;

  const referrer =
    document.referrer && document.referrer !== "" ? document.referrer : null;

  to.meta = {
    referrer,
    fromOtherRoute: false,
    fromExternal: false,
  };

  if (from) {
    to.meta.fromExternal =
      referrer === null || (referrer && !referrer.includes(from.fullPath));

    to.meta.fromOtherRoute = from.fullPath !== to.fullPath;
  }
});
