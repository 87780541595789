export const mobileAppSlider = {
  candidates: [
    {
      src: "/img/candidats/ecran-ae.jpeg",
      alt: "screenshot 1",
    },
    {
      src: "/img/candidats/ecran-cdi.jpeg",
      alt: "screenshot 2",
    },
    {
      src: "/img/candidats/ecran-interim.jpeg",
      alt: "screenshot 3",
    },
  ],
};
