export const pushTuturialVideo = {
  candidate: {
    imgDesktop: "/img/candidats/screen-video.png",
    imgMobile: "/img/candidats/screen-video-mobile.png",
  },
  etab: {
    imgDesktop: "/img/recruteurs/screen-video.png",
    imgMobile: "/img/recruteurs/screen-video-mobile.png",
  },
};
