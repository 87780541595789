import type { FetchError } from "ofetch";
import type { JobsOffersQuery } from "~/models/Api";
import HttpFactory from "~/repository/factory";

class JobsModule extends HttpFactory {
  public resourcePath = "/joboffer/public/search-offers";

  async list({ page, q, c, cp, nb }: JobsOffersQuery = {}) {
    return await useAsyncData<FetchError>("jobOffers", () => {
      const params = {
        page,
        q,
        c,
        cp,
        nb,
      };

      return this.call({
        method: "GET",
        url: this.resourcePath,
        fetchOptions: {
          params,
        },
      });
    });
  }
}

export default JobsModule;
