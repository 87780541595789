export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const TOLK_IDS = [
    "chatbot-script-init",
    "chatbot-script",
    "chatbot-insert",
    "tolk-widget",
  ];

  const ROUTE_TOLK_CONFIG = {
    "/faq/candidats": config.public.tolkIdCandidat,
  };

  const tolk = {
    mount: (tolkId: string) => {
      const scriptInit = document.createElement("script");
      scriptInit.type = "text/javascript";
      scriptInit.id = TOLK_IDS[0];
      scriptInit.innerHTML = `
        var tcfbot = "${tolkId}";
        var TcfWbchtParams = { behaviour: "default" };
        var display = "iframe";
      `;
      document.body.appendChild(scriptInit);

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://script.tolk.ai/iframe-latest.js";
      script.id = TOLK_IDS[1];
      document.body.appendChild(script);
    },
    unmount: (ids: string[] = TOLK_IDS) => {
      ids.forEach((id) => {
        let element;
        while ((element = document.getElementById(id))) {
          element.remove();
        }
      });
    },
  };

  let previousTolkId: string | undefined;

  nuxtApp.hook("page:finish", () => {
    const { currentRoute } = useRouter();

    const tolkId = Object.entries(ROUTE_TOLK_CONFIG).find(([route]) =>
      currentRoute.value.path.startsWith(route),
    )?.[1];

    if (tolkId) {
      if (tolkId !== previousTolkId) {
        tolk.unmount();
        tolk.mount(tolkId);
        previousTolkId = tolkId;
      }
    } else {
      tolk.unmount();
      previousTolkId = undefined;
    }
  });
});
