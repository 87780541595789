import { routes } from "~/constants";
export const banner = {
  classic: true,
  text: "Futur auto-entrepreneur ?<br /> Estime ton revenu !",
  cta: "calculer mon revenu",
  picto: "/img/calculateur-ae-euro-picto.png",
  altTitle: "Logo symbole euro",
  url: routes.simulatorCaAe,
  utm: "banner-simulateur-ae",
};
