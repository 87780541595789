import { routes } from "~/constants";
export const logistiqueVente = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/w9CAKW76",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 entreprises",
    title3: "nous font confiance !",
    subtitle: "Magasins, Entrepôts, Grandes et moyennes surfaces...",
  },
  qualifications: {
    title: "Ensemble, trouvons les meilleurs profils pour renforcer vos équipes",
    list: [
      {
        qualificationCategory: "Logistique",
        qualificationImgSrc:
          "/img/recruteurs/bu/logistique-vente/qualification-logistique.jpeg",
        qualificationList: [
          {
            name: "Manutentionnaire",
            slug: "manutentionnaire",
            informations: {
              definition: {
                title:
                  "Quel est l'impact d'un Manutentionnaire dans la chaîne logistique ?",
                text: "Le <strong>manutentionnaire</strong> d'entrepôt ou de magasin joue un rôle clé dans la logistique et l'organisation. Responsable de la réception, du stockage, et de la préparation des marchandises, il assure que les produits sont correctement triés, stockés et expédiés. Sa précision et son efficacité sont essentielles pour maintenir l'ordre et la fluidité des opérations, garantissant ainsi le bon fonctionnement du magasin ou de l'entrepôt. Des centaines de Manutentionnaires qualifiés à Paris et à Lyon sont chez Extracadabra !",
              },
            },
          },
          {
            name: "Préparateur de commandes",
            slug: "préparateur-de-commandes",
            informations: {
              definition: {
                title:
                  "Comment un Préparateur de Commandes optimise-t-il la logistique ?",
                text: "Le <strong>préparateur de commandes</strong> en entrepôt est essentiel au bon fonctionnement de la chaîne logistique. Avec précision et rapidité, il rassemble, vérifie et prépare les produits pour l'expédition. Sa capacité à suivre scrupuleusement les bons de commande et à organiser efficacement les colis est cruciale pour assurer des livraisons justes et dans les temps, jouant un rôle majeur dans la satisfaction des clients finaux.",
              },
            },
          },
          {
            name: "Agent d’entretien",
            slug: "agent-d-entretien",
            informations: {
              definition: {
                title:
                  "Pourquoi un Agent d'Entretien est-il essentiel dans la logistique ?",
                text: "L'<strong>agent d'entretien</strong> est crucial pour maintenir un environnement propre et sain. Responsable du nettoyage et de l'entretien des espaces, il applique des techniques rigoureuses pour assurer une hygiène irréprochable. Son travail méticuleux et son attention aux détails sont essentiels pour garantir des lieux accueillants et sécurisés pour tous les clients.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Vente",
        qualificationImgSrc:
          "/img/recruteurs/bu/logistique-vente/qualification-vente.jpeg",
        qualificationList: [
          {
            name: "Vendeur",
            slug: "vendeur",
            informations: {
              definition: {
                title: "Quelles qualités distinguent un Vendeur en boutique ?",
                text: "Le <strong>vendeur en boutique</strong> est un expert de la mode ou des articles qu’il vend. Il aide les clients à trouver les vêtements, les accessoires, les produits qui correspondent à leurs attentes. Sa connaissance des dernières tendances et son sens du service client créent une expérience d'achat enrichissante, contribuant à la satisfaction et à la fidélisation de la clientèle.",
              },
            },
          },
          {
            name: "Hôte de caisse",
            slug: "hote-de-caisse",
            informations: {
              definition: {
                title:
                  "Quelle est l'importance d'un Hôte de Caisse dans le processus de vente ?",
                text: "L'<strong>hôte de caisse</strong> ou <strong>caissier</strong> s’occupe des transactions, c’est le point de contact clef avec les clients. Il s'assure que l'expérience de paiement soit fluide et agréable pour le client. Doté de compétences en communication et d'une attitude amicale, il répond aux questions, résout les problèmes liés aux paiements et contribue à une expérience d'achat positive.",
              },
            },
          },
          {
            name: "Employé Libre Service",
            slug: "employe-libre-service",
            informations: {
              definition: {
                title: "Quel est le rôle d'un Employé Libre Service dans une boutique ?",
                text: "L'<strong>employé libre-service</strong> en boutique joue un rôle essentiel dans l'organisation et la présentation des produits. Il se doit d’être polyvalent pour effectuer différentes tâches en fonction des rayons. Il assure la mise en rayon, le réassortiment et le maintien de la propreté des étagères. Son attention au détail garantit une présentation attrayante des produits, facilitant ainsi l'expérience d'achat des clients. Sa connaissance des produits et son sens du service client lui permettent également d’orienter le client dans le magasin.",
              },
            },
          },
          {
            name: "Responsable Point de Vente",
            slug: "responsable-point-de-vente ",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités d'un Responsable de Point de Vente ?",
                text: "Le <strong>responsable de point de vente</strong> est le pilier central de la boutique, assurant la gestion quotidienne et la performance commerciale. Il dirige et motive son équipe, gère les stocks, s'assure de la présentation optimale des produits et veille à offrir une expérience client exceptionnelle. Doté d'un sens aigu des affaires et d'excellentes compétences en communication, il analyse les tendances de vente pour adapter les stratégies commerciales et maximiser les résultats. Sa capacité à créer un environnement de travail positif et productif est essentielle pour le succès et la croissance du point de vente !",
              },
            },
          },
          {
            name: "Vendeur en jardinerie",
            slug: "vendeur-en-jardinerie",
            informations: {
              definition: {
                title: "En quoi consiste l'expertise d'un Vendeur en Jardinerie ?",
                text: "Le <strong>vendeur en jardinerie</strong> est un passionné de nature et un expert en produits de jardinage. Il a une connaissance approfondie des plantes, des outils et des produits pour le jardin. Il conseille les clients en fonction de leurs besoins spécifiques et des saisons. Son expertise en botanique et en entretien des espaces verts permet d'offrir des recommandations personnalisées à chaque projet de jardinage de ses clients.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Commercial / Administratif",
        qualificationImgSrc:
          "/img/recruteurs/bu/logistique-vente/qualification-administratif.jpeg",
        qualificationList: [
          {
            name: "Hôte / hôtesse d’accueil",
            slug: "hote-d-accueil",
            informations: {
              definition: {
                title:
                  "Quels sont les rôles et compétences d'un Hôte ou d'une Hôtesse d’Accueil ?",
                text: "La mission de <strong>l’hôte ou hôtesse d’accueil</strong> est de transformer chaque accueil en un moment mémorable et agréable. Garant de l’image que va renvoyer le magasin,, il/elle est doté de compétences en communication, il/elle accueille les visiteurs, les guide et leur fournit les informations nécessaires. L’hôte/hôtesse d’accueil doit avoir le sens du service, du dynamisme et un large sourire ! De nombreux talents avec une expérience d’hôte ou d’hôtesse d’accueil n’attendent que vous à Paris et à Lyon sur la plateforme Extracadabra.",
              },
            },
          },
          {
            name: "Opérateur téléphonique (service client)",
            slug: "operateur-telephonique",
            informations: {
              definition: {
                title:
                  "Quelle est la contribution d'un Opérateur Téléphonique au service client ?",
                text: "L'<strong>opérateur téléphonique</strong> en service client est le maître de la communication et le gardien de la satisfaction client. Pour assurer un bon service client, il se doit d’être empathique, d’avoir une bonne capacité d’écoute et apporter des solutions rapidement aux clients. Son rôle est essentiel pour maintenir une relation client positive et renforcer la confiance envers l'entreprise.",
              },
            },
          },
          {
            name: "Commercial",
            slug: "commercial",
            informations: {
              definition: {
                title:
                  "Comment un Commercial influence-t-il le succès d'une entreprise ?",
                text: "Le <strong>commercial</strong> est un véritable as de la vente, s'adaptant rapidement à divers environnements et marchés. Il est capable de nouer des relations facilement et rapidement. Il excelle dans la négociation et la persuasion. Agile et réactif, il identifie les opportunités et répond efficacement aux besoins des clients, il facilite ainsi le succès de l’entreprise pour laquelle il travaille.",
              },
            },
          },
          {
            name: "Agent administratif",
            slug: "agent-administratif",
            informations: {
              definition: {
                title:
                  "Quel est le rôle crucial d'un Agent Administratif dans une entreprise ?",
                text: "L'<strong>agent administratif</strong>, c'est le champion de l'organisation. Dans les bureaux, il est là pour gérer les dossiers, il jongle avec les paperasses avec une aisance impressionnante. Toujours prêt à classer, archiver et gérer, il est le pilier incontournable pour faire avancer des sujets. ",
              },
            },
          },
        ],
      },
    ],
  },
  faqs: {
    title: "Recruteurs : Vos questions, nos réponses",
    list: [
      {
        question: "Combien coûte un Extra ?",
        answer:
          "Chez Extracadabra, le coût d'un extra ou renfort en logistique et vente est fixe et sans surprise. Vendeur, manutentionnaire, préparateur de commandes… En fonction de la qualification que vous recherchez, le tarif de la prestation sera ajusté. Vous trouverez par exemple, votre futur manutentionnaire pour 16,90 euros de l’heure",
      },
      {
        question: " Qu’est-ce qui est compris dans vos tarifs ?",
        answer:
          "Nos tarifs comprennent la rémunération de nos extras et la commission d’Extracadabra, car nous nous occupons de toute la partie administrative liée à vos prestations.",
      },
      {
        question: "Comment travailler avec Extracadabra ?",
        answer:
          "Extracadabra, c'est simple comme un claquement de doigt ! En plus de notre plateforme en ligne, vous pouvez prendre contact directement par téléphone pour un accompagnement personnalisé. Vous obtiendrez une réponse à toutes les questions que vous vous posez quels que soient vos besoins. Autrement, il ne vous reste plus qu'à créer votre compte sans engagement. Après quelques questions et 5 minutes de votre temps, votre compte sera créé et vous pourrez poster votre première demande gratuitement pour trouver le talent qu'il vous faut !",
      },
      {
        question: "Quelles sont les qualifications proposées par Extracadabra ? ",
        answer:
          "Chez Extracadabra nous avons un large vivier de candidats qualifiés dans les secteurs de la logistique et vente. Que vous recherchiez des préparateurs de commandes efficaces ou des responsables de point de vente chaleureux, nous avons le candidat parfait pour compléter votre équipe !",
      },
      {
        question: "Extracadabra est-il un spécialiste du secteur de la logistique ?",
        answer:
          "L’entreprise est né en 2017, nous avons commencé avec le secteur de la restauration, puis nous avons étendu notre viver à la vente et rapidement à la logistique ! De nombreuses entreprises travaillent déjà avec nous en entrepôts ou magasins comme Bergamotte, Cabaïa ou Foodchéri.",
      },
    ],
  },
  otherCities: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: "#",
        label: "EN SAVOIR PLUS",
      },
      title: "Trouvez les meilleurs talents <strong>à Lyon</strong> avec Extracadabra",
      description:
        "Confiez-nous vos besoins de recrutement à Lyon et bénéficiez de notre expertise pour dénicher les candidats les plus qualifiés",
    },
  ],
  leadGen: {
    book: {
      src: "/img/recruteurs/book_lead_gen.png",
      alt: "illustration du e-book Extracadabra",
    },
  },
  footer: {
    legals: {
      cancelation: `*Si vous n’annulez pas une prestation moins de 72 heures avant. <a href='${routes.cgu}'>en savoir plus</a>`,
    },
  },
};
