export const partnerItem = {
  CANDIDATES: [
    {
      brandName: "Alan",
      logoSrc: "/img/partenaires/candidats/alan.png",
      subtitleColor: "#6160f5",
      ctaUrl: "https://alan.com/fr-fr/p/extracadabra",
    },
    {
      brandName: "Blank",
      logoSrc: "/img/partenaires/candidats/blank.png",
      subtitleColor: "#3c94ff",
      ctaUrl:
        "https://landing.blank.app/extracadabra?utm_medium=partnership&utm_source=espace_partenaire&utm_campaign=extracadabra&utm_content=",
    },
    {
      brandName: "Superindep.fr",
      logoSrc: "/img/partenaires/candidats/superindep.png",
      subtitleColor: "#1d0f68",
      ctaUrl: "https://www.superindep.fr/partenaire/extracadabra-creation.html",
    },
    {
      brandName: "Cautioneo",
      logoSrc: "/img/partenaires/candidats/cautioneo.png",
      subtitleColor: "#4842e3",
      ctaUrl: "https://lp.cautioneo.com/extracadabra/",
    },
    {
      brandName: "En voitue simone",
      logoSrc: "/img/partenaires/candidats/en-voiture-simone.png",
      subtitleColor: "#6E949A",
      ctaUrl: "https://www.envoituresimone.com/partenaires/extracadabra",
    },
    {
      brandName: "Manelli",
      logoSrc: "/img/partenaires/candidats/manelli.webp",
      subtitleColor: "#FCC223",
      ctaUrl: "https://www.manelli.fr/",
    },
    {
      brandName: "Monsieur Veste",
      logoSrc: "/img/partenaires/candidats/monsieur-veste.webp",
      subtitleColor: "#EA5C18",
      ctaUrl: "https://www.monsieur-veste.fr/fr/113-packs",
    },
    {
      brandName: "Duthilleul & Minart",
      logoSrc: "/img/partenaires/candidats/duthilleul-et-minart.webp",
      subtitleColor: "#0072C6",
      ctaUrl: "http://www.duthilleuletminart.fr/",
    },
  ],
  ETAB: [
    {
      brandName: "Sunday",
      logoSrc: "/img/partenaires/recruteurs/sunday.png",
      ctaUrl: "#",
    },
    {
      brandName: "Epicery",
      logoSrc: "/img/partenaires/recruteurs/epicery.png",
      ctaUrl: "#",
    },
    {
      brandName: "La Table",
      logoSrc: "/img/partenaires/recruteurs/la-table.png",
      ctaUrl: "#",
    },
  ],
};
