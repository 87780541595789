import { routes } from "~/constants";
export const commerceDeBouche = {
  popup: {
    src: "https://extracadabra-paris.typeform.com/to/s07Za7w0",
  },
  partnersSlider: {
    title1: "Plus de",
    title2: "10 000 clients",
    title3: "prêts à nous recommander en…",
    subtitle:
      "Boulangeries, épiceries, restauration rapide, boucherie et autres commerces de bouche...",
  },
  qualifications: {
    title: "Ensemble, trouvons les meilleurs profils pour renforcer vos équipes",
    list: [
      {
        qualificationCategory: "Vente",
        qualificationImgSrc: "/img/recruteurs/bu/commerce-bouche/qualification-vente.jpg",
        qualificationList: [
          {
            name: "Vendeur en boulangerie",
            slug: "vendeur-en-boulangerie",
            informations: {
              definition: {
                title: "Quelles expertises distinguent un Vendeur en Boulangerie ?",
                text: "Le <strong>vendeur en boulangerie</strong> est certainement le commerçant qui voit défiler le plus de clients en une seule journée. Il est quotidiennement le commerçant de tout bon français qui se respecte. Tous les types de pains n’ont aucun secret pour lui ! Avec le sourire, il conseille sur le choix des produits (pains, viennoiseries, pâtisseries…), sert et tient la caisse. Recrutez des vendeurs en boulangerie qualifiés au sein de la communauté Extracadabra.",
              },
            },
          },
          {
            name: "Employé polyvalent de restauration",
            slug: "employe-polyvalent-restauration",
            informations: {
              definition: {
                title: "Quel est le profil d'un Employé Polyvalent en Restauration ?",
                text: "L'<strong>employé polyvalent</strong> en restauration est le véritable couteau suisse du restaurant. Polyvalent et adaptable, il jongle habilement entre cuisine et service. Que ce soit pour préparer des plats simples, servir les clients avec le sourire ou veiller à la propreté des lieux, il est au cœur de l'expérience client dans des lieux de restauration rapide par exemple. Il doit posséder des qualités d’adaptabilité, un dynamisme certain et le sens du service. Les meilleurs employés polyvalents en restauration sont dans la communauté Extracadabra.",
              },
            },
          },
          {
            name: "Responsable point de vente",
            slug: "responsable-point-de-vente",
            informations: {
              definition: {
                title:
                  "Quelles sont les compétences clés d'un Responsable de Point de Vente ?",
                text: "Le <strong>responsable de point de vente</strong> dirige avec leadership le magasin, lieux de restauration rapide ou commerce de bouche dont il a la responsabilité.  De la gestion d'équipe à l'optimisation des ventes, en passant par le suivi des stocks et la satisfaction client, il a un œil sur tout. Son objectif ? Propulser le point de vente vers les sommets en termes de chiffre d’affaires. La principale qualité du responsable de point de vente est sa grande capacité relationnelle et son sens aigu des affaires.",
              },
            },
          },
          {
            name: "Préparateur de commandes",
            slug: "preparateur-de-commande",
            informations: {
              definition: {
                title:
                  "Quel est l'impact d'un Préparateur de Commandes dans la logistique ?",
                text: "Dans les métiers de la logistique, le <strong>préparateur de commandes</strong> est un maillon essentiel. Rapidement, il rassemble et prépare les produits pour expédition ou retrait en magasin. Son rôle va au-delà de la simple préparation : il s'assure de l'exactitude des commandes, gère l'inventaire et veille au bon conditionnement des produits. A l’heure du e-commerce, son rôle est crucial pour garantir la satisfaction client et maintenir l'efficacité opérationnelle.",
              },
            },
          },
          {
            name: "Vendeur en boucherie",
            slug: "vendeur-en-boucherie",
            informations: {
              definition: {
                title: "En quoi consiste l'expertise d'un Vendeur en Boucherie ?",
                text: "Le <strong>vendeur en boucherie</strong> est un expert ! Au sein de la boucherie, avec sa connaissance approfondie des différents types de viandes, de leur découpe et de leur préparation, il guide les clients dans leurs choix, offrant des conseils avisés pour cuisiner chaque pièce aux petits oignons. Son engagement envers la qualité et son sens du service personnalisé font de lui un excellent conseiller culinaire, pro de la découpe de chaque type de morceaux. Les meilleurs profils de vendeurs en boucherie sont chez Extracadabra !",
              },
            },
          },
          {
            name: "Vendeur en charcuterie",
            slug: "vendeur-en-charcuterie",
            informations: {
              definition: {
                title: "Quelles sont les spécificités d'un Vendeur en Charcuterie ?",
                text: "Chez un traiteur ou dans une boucherie/charcuterie, le vendeur offre une expertise dans la sélection et la présentation de produits de qualité. Il possède une connaissance approfondie des différentes variétés de charcuteries, saucisses, jambons, et autres spécialités et conseille les clients sur le meilleur choix pour leurs besoins culinaires. Il maîtrise à la perfection la balance, les prix des articles et la caisse.",
              },
            },
          },
          {
            name: "Vendeur en fromagerie",
            slug: "vendeur-en-fromagerie",
            informations: {
              definition: {
                title:
                  "Quel est le rôle d'un Vendeur en Fromagerie dans le terroir français ?",
                text: "S’il y a bien un fin connaisseur du terroir français, c’est le <strong>vendeur en fromagerie</strong> ! Doté d'une connaissance approfondie des variétés de fromages, de leurs origines, et de leurs caractéristiques gustatives, il guide les clients dans un voyage de saveurs. Son expertise permet de conseiller sur les meilleurs accords fromages et vins, et de proposer des suggestions pour des plateaux de fromages en fonction des occasions. Son rôle est essentiel pour offrir une expérience d'achat unique, garantissant la satisfaction et la fidélisation des clients.",
              },
            },
          },
          {
            name: "Vendeur en poissonnerie",
            slug: "vendeur-en-poissonnerie",
            informations: {
              definition: {
                title: "Quelles connaissances distinguent un Vendeur en Poissonnerie ?",
                text: "Le <strong>vendeur en poissonnerie</strong> est le spécialiste des produits de la mer, offrant une expertise inégalée en matière de poissons et fruits de mer. Il possède une connaissance approfondie des différentes espèces, de leurs saisons, et des meilleures façons de les préparer et de les cuisiner. Attentif, il veille à la fraîcheur du poisson, et saura conseiller ses clients en fonction de leurs besoins.",
              },
            },
          },
        ],
      },
      {
        qualificationCategory: "Cuisine",
        qualificationImgSrc:
          "/img/recruteurs/bu/commerce-bouche/qualification-cuisine.jpg",
        qualificationList: [
          {
            name: "Pâtissier",
            slug: "patissier",
            informations: {
              definition: {
                title:
                  "Quelles sont les qualifications et la journée type d'un Pâtissier ?",
                text: "Le <strong>pâtissier</strong>, c’est l’artisan de la gourmandise ! Issu d’un CAP ou d’un Bac pro, il est  doté d'un savoir-faire pointu pour élaborer les gâteaux et autres desserts dont nous raffolons. Il combine créativité et maîtrise technique pour émerveiller les papilles. C’est aussi un lève-tôt qui arrive souvent le premier dans la boutique afin d’oeuvrer dans le laboratoire. Recruter les meilleurs pâtissier au sein de la communauté Extracadabra.",
              },
            },
          },
          {
            name: "Chef de partie",
            slug: "chef-de partie",
            informations: {
              definition: {
                title:
                  "Quelles sont les responsabilités d'un Chef de Partie en Restauration Rapide ?",
                text: "Le <strong>chef de partie en restauration rapide</strong> est responsable d’une partie du menu, cela peut-être les desserts, les légumes, les viandes entre autres. Il maîtrise parfaitement les plats qui sont à la carte. Il assure la préparation rapide et la qualité des plats, tout en veillant à respecter les normes d'hygiène et de sécurité alimentaire. Sa capacité à travailler rapidement sous pression et à innover dans la création de menus rend son rôle crucial pour le succès de l'établissement.",
              },
            },
          },
          {
            name: "Commis de cuisine",
            slug: "commis-de-cuisine",
            informations: {
              definition: {
                title:
                  "Comment un Commis de Cuisine contribue-t-il à une cuisine rapide et de qualité ?",
                text: "Le <strong>commis de cuisine en restauration rapide</strong> est un élément vital de l'équipe en cuisine. Il prépare les ingrédients, assiste le chef ou le chef de partie dans la réalisation des plats et veille au maintien de la propreté de la cuisine, il a idéalement une formation HACCP. Sa rapidité et sa capacité à exécuter les ordres garantissent un service efficace et de qualité.",
              },
            },
          },
          {
            name: "Plongeur",
            slug: "plongeur",
            informations: {
              definition: {
                title:
                  "Quel est l'impact d'un Plongeur dans une cuisine de restauration rapide ?",
                text: "Le <strong>plongeur en restauration rapide</strong> veille au maintien de la propreté de l’espace de production en collectant et lavant les ustensiles de cuisine. Rapide et efficace, il gère le nettoyage de la vaisselle, permettant ainsi que tout soit en ordre. Son rôle est essentiel pour garantir une expérience culinaire saine et agréable pour les clients. De nombreux plongeurs sont disponibles au sein de la communauté Extracadabra.",
              },
            },
          },
        ],
      },
    ],
  },
  faqs: {
    title: "Recruteurs : Vos questions, nos réponses",
    list: [
      {
        question: "Combien coûte un Extra ?",
        answer:
          "Chez Extracadabra, le coût d'un extra ou renfort en commerce de bouche est fixe et sans surprise.<br /><br /> Vendeur, commis de cuisine, employé polyvalent… En fonction de la qualification que vous recherchez, le tarif de la prestation sera ajusté.<br /><br />Vous trouverez par exemple, votre futur vendeur en boulangerie pour 18,90 euros de l’heure. ",
      },
      {
        question: " Qu’est-ce qui est compris dans vos tarifs ?",
        answer:
          "La rémunération de votre extra et la commission d’Extracadabra.<br /><br />Vous n’aurez plus rien à ajouter.",
      },
      {
        question: "Comment travailler avec Extracadabra ?",
        answer:
          "Extracadabra, c'est simple comme un claquement de doigt ! Créer votre compte en ligne ou sur notre application (iOS et Android) sans engagement. Après avoir répondu à quelques questions, en seulement 2 minutes, vous pourrez déposer une première annonce gratuitement. Et si vous avez des questions ou une hésitation, pas de problème ! Contactez directement nos équipes par téléphone.",
      },
      {
        question: "Quelles sont les qualifications proposées par Extracadabra ? ",
        answer:
          "Extracadabra se distingue par son large éventail de professionnels qualifiés dans les secteurs de la vente et de la restauration rapide. Que vous recherchiez des runners dynamiques, des vendeurs sympathiques ou des chocolatiers talentueux, nous avons le candidat parfait pour compléter votre équipe !",
      },
      {
        question: "Le service Extracadabra est-il disponible dans toute la France ?",
        answer:
          "Pas encore ! Nous sommes disponibles à Paris et à Lyon pour vous accompagner dans la constitution de vos équipes en boutiques, commerces de bouche ou restauration rapide.",
      },
    ],
  },
  otherCities: [
    {
      imgSrc: "/img/recruteurs/bu/photo-lyon-extracadabra.png",
      imgAlt: "Photo de la ville de Lyon - Extracadabra",
      cta: {
        url: "#",
        label: "EN SAVOIR PLUS",
      },
      title: "Trouvez les meilleurs talents <strong>à Lyon</strong> avec Extracadabra",
      description:
        "Confiez-nous vos besoins de recrutement à Lyon et bénéficiez de notre expertise pour dénicher les candidats les plus qualifiés",
    },
  ],
  leadGen: {
    book: {
      src: "/img/recruteurs/traiteur-evenementiel/book_lead_gen.png",
      alt: "illustration du e-book Extracadabra",
    },
  },
  footer: {
    legals: {
      cancelation: `*Si vous n’annulez pas une prestation moins de 72 heures avant. <a href='${routes.cgu}'>en savoir plus</a>`,
    },
  },
};
