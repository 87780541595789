import { defineNuxtPlugin } from "#app";
import posthog from "posthog-js";
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const posthogClient = posthog.init(config.public.posthogPublicKey, {
    api_host: config.public.posthogHost || "https://eu.i.posthog.com",
    persistence: "memory",
    autocapture: true,
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      if (import.meta.env.MODE === "development") posthog.debug();
    },
  });

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture("$pageview", {
        current_url: to.fullPath,
      });
    });
  });

  return {
    provide: {
      posthogClient,
    },
  };
});
