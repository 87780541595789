import { routes } from "~/constants";
export const pushRessources = [
  {
    imgDesktop: "/img/jobs-desktop.png",
    imgMobile: "/img/jobs-mobile.png",
    url: routes.jobsUrl.index,
    external: true,
  },
  {
    imgDesktop: "/img/blog-desktop.png",
    imgMobile: "/img/blog-mobile.png",
    url: routes.blogHomeUrl,
    external: false,
  },
];
