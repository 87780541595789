import type { AsyncDataOptions } from "#app";
import type { APIParams, APIResponse } from "~/models/Api";
import type { Verbatims } from "~/models/Verbatim";
import HttpFactory from "~/repository/factory";

class VerbatimsModule extends HttpFactory {
  private resourcePath = "/api/verbatims";

  async list<T = APIResponse<Verbatims>>(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<T>,
  ) {
    const cacheKey = `verbatims::${JSON.stringify(filters ?? {})}`;
    return await useAsyncData<T>(
      cacheKey,
      () => {
        const params = {
          populate: {
            logo: true,
            photo: true,
            verbatim_group: true,
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }
}

export default VerbatimsModule;
