import { PostHog } from "posthog-node";

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();

  const posthog = new PostHog(runtimeConfig.public.posthogPublicKey, {
    host: runtimeConfig.public.posthogHost,
  });

  return {
    provide: {
      posthog,
    },
  };
});
